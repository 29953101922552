import { useDispatch, useSelector } from 'react-redux'
import { selectDocumentState, setUserWriterSelections } from '../../redux/docSlice'
import { DocumentState } from '../../redux/types'
import { Tooltip } from '@mui/material'
import ReferencesDropdown from './ReferencesDropdown'

const ReferencesSelection = ({ docID }: { docID: string }) => {
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const dispatch = useDispatch()

	return (
		<div
			className="flex items-center justify-between mt-10"
			onClick={(e) => {
				const includeRefs =
					documentState && documentState.userWriterSelections && !!documentState?.userWriterSelections?.refStyle

				dispatch(
					setUserWriterSelections({
						docID: docID,
						userWriterSelections: {
							includeRefs: includeRefs,
						},
					})
				)
			}}
		>
			<Tooltip
				title="The AI model cannot ensure all references are accurate. Additionally, the model will not always use outside sources when constructing your content."
				placement="top-start"
			>
				<div id="refsLabel" className="md:text-2xl text-xl font-semibold">
					References Style:
				</div>
			</Tooltip>
			<ReferencesDropdown docID={docID} />
		</div>
	)
}

export default ReferencesSelection
