import { useMemo } from 'react'
import { useDocId } from '../../hooks/docID'
import { DocumentState } from '../../redux/types'
import { useSelector } from 'react-redux'
import { selectDocumentState } from '../../redux/docSlice'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'
import { blankUserWriterSelections } from '../../redux/constants'

export const useProcessedSubtopics = () => {
	const docID = useDocId()

	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	return useMemo(() => {
		if (!documentState) return []
		// change the first and last subtopics to be "Introduction" and "Conclusion"
		let subtopics = [...documentState.userWriterSelections.subTopics]
		if (subtopics.length === 0) return []
		subtopics[0] = 'Introduction'
		subtopics[subtopics.length - 1] = 'Conclusion'
		return subtopics
	}, [documentState?.userWriterSelections.subTopics])
}

export const useBlankUserWriterSelections = () => {
	const wordCount = 150
	let defaultUserWriterSelections = blankUserWriterSelections
	defaultUserWriterSelections.wordCount = wordCount
	return defaultUserWriterSelections
}
