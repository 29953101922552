import React, { useRef } from 'react'

function VerificationCodeInput({
	code,
	setCode,
	invalid,
	setInvalidCode,
}: {
	code: string[]
	setCode: (code: string[]) => void
	invalid: boolean
	setInvalidCode: (invalid: boolean) => void
}) {
	const inputsRef = useRef([])

	// Handle input changes
	const handleChange = (e: any, index: number) => {
		setInvalidCode(false)
		const value = e.target.value
		if (/\D/.test(value)) return // Allow only digits

		const newCode = [...code]
		newCode[index] = value.slice(-1) // Ensure only one digit per box

		setCode(newCode)

		// Move focus to next input if not the last box
		if (value && index < 3) {
			// @ts-ignore
			inputsRef.current[index + 1].focus()
		}
	}

	// Handle key down events
	const handleKeyDown = (e: any, index: number) => {
		if (e.key === 'Backspace' && !code[index] && index > 0) {
			// @ts-ignore
			inputsRef.current[index - 1].focus()
		}
	}

	// Handle paste events
	const handlePaste = (e: any) => {
		e.preventDefault()
		const pasteData = e.clipboardData.getData('text').replace(/\D/g, '').slice(0, 4)

		const newCode = pasteData.split('')
		setCode([...newCode, '', '', '', ''].slice(0, 4))

		// @ts-ignore
		inputsRef.current[newCode.length - 1]?.focus()
	}

	return (
		<>
			<div onPaste={handlePaste} className="flex justify-center gap-1">
				{code.map((digit, index) => (
					<input
						key={index}
						type="text"
						inputMode="numeric"
						placeholder="-"
						maxLength={1}
						value={digit}
						onChange={(e) => handleChange(e, index)}
						onKeyDown={(e) => handleKeyDown(e, index)}
						// @ts-ignore
						ref={(el) => (inputsRef.current[index] = el)}
						className={`border rounded w-14 h-16 text-center text-3xl font-semibold outline-none ${
							invalid ? 'border-red-500' : 'border-gray-300'
						}`}
					/>
				))}
			</div>
			{invalid && <p className="text-red-500 text-sm text-center">Invalid code</p>}
		</>
	)
}

export default VerificationCodeInput
