import { useSelector } from 'react-redux'
import { selectUser } from '../redux/systemSlice'
import { useToken } from './token'
import { useAuth } from '../contexts/Auth'
import * as Sentry from '@sentry/react'

function useCreatePortalSession() {
	const { currentUser } = useAuth() as any
	const user = useSelector(selectUser)
	const userToken = useToken()

	const createPortalSession = () => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${userToken}` : '',
			},
			body: JSON.stringify({
				userId: user.id,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/payments/portal-session/', requestOptions)
			.then((res) => res.json())
			.then((response: { url: string }) => {
				if (response.url) {
					window.open(response.url, '_self')
				}
			})
			.catch((err) => Sentry.captureException(err))
	}

	return createPortalSession
}

export default useCreatePortalSession
