import { Link } from 'react-router-dom'
import LogoImg from '../assets/images/logo.svg'
import LogoLightImg from '../assets/images/logolight.svg'

const Logo = ({ light = false, small = false }: { light?: boolean; small?: boolean }) => {
	return (
		<Link to="/">
			<img src={light ? LogoLightImg : LogoImg} alt="Clarify.ai" className={small ? 'md:h-8 h-6' : 'md:h-9 h-7'} />
		</Link>
	)
}

export default Logo
