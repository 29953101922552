import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '../../contexts/Auth'
import { selectUser, setUser } from '../../redux/systemSlice'
import Button from '../Buttons'
import Premium from '../Premium/Premium'
import './_Modal.scss'
import { isValidEmail, useLocalStorage } from '../../helpers/utility'
import * as Sentry from '@sentry/react'
import './_Account.scss'
import ReauthModal from './Reauth'
import Modal from './Modal'
import LoginModal from './Login'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import useIsPremium from '../../hooks/useIsPremium'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'
import useCreatePortalSession from '../../hooks/useBillingPortal'

const errorToText = {
	'email-not-changed': 'To change your account email, please enter a different email than your current email.',
	'auth/email-already-in-use': 'This email is already used by another account, please try again with a new email',
	'invalid-email': 'Please enter a valid email',
	unknown: 'Unknown error, please try again.',
}

function AccountModal({ errorProp, isOpen, closeModal }: { errorProp?: any; isOpen: boolean; closeModal: () => void }) {
	const { logout, currentUser } = useAuth() as any
	const [emailInput, setEmailInput] = useState(currentUser ? currentUser.email : '')
	const user = useSelector(selectUser)
	const dispatch = useDispatch()
	const [error, setError] = useState<
		undefined | 'email-not-changed' | 'auth/email-already-in-use' | 'invalid-email' | 'unknown'
	>(undefined)
	const [reauthModalOpen, setReauthModalOpen] = useState(false)
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const [annualPlan, setAnnualPlan] = useLocalStorage('annualPlan', true)
	const hasPremiumAccess = useIsPremium()
	const createPortalSession = useCreatePortalSession()

	const isSignedInWithGoogle = currentUser?.providerData.some((provider: any) => provider.providerId === 'google.com')

	useEffect(() => {
		if (currentUser) {
			setEmailInput(currentUser ? currentUser.email : '')
		}
	}, [currentUser])

	useEffect(() => {
		setError(errorProp)
		//Update only on initial page load
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	async function logoutUser() {
		closeModal()
		await logout()
		window.location.reload()
	}

	async function openReauth() {
		const currentEmail = currentUser?.email ?? ''

		if (emailInput === currentEmail) {
			setError('email-not-changed')
			return
		}

		if (!isValidEmail(emailInput)) {
			setError('invalid-email')
			return
		}

		setReauthModalOpen(true)
	}

	async function disableHumanizer() {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				userId: user.id,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/payments/delete-humanizer/', requestOptions)
			.then(() => {
				dispatch(setUser({ ...user, hasPaidAddon: false }))
			})
			.catch((err) => Sentry.captureException(err))
	}

	if (reauthModalOpen) {
		return (
			<ReauthModal
				newEmail={emailInput?.toLowerCase()}
				closeModal={() => setReauthModalOpen(false)}
				isOpen={reauthModalOpen}
				setError={setError}
			/>
		)
	}

	return (
		<>
			<LoginModal
				premiumPath={true}
				open={loginModalOpen}
				setModalOpen={(open: boolean) => {
					setLoginModalOpen(false)
				}}
				annualPlan={annualPlan}
			/>
			<Modal portalClassName="account" open={isOpen} closeModal={closeModal}>
				<>
					<h5 className=" header-md">Your Account</h5>
					<div className="flex-container">
						<div className="main">
							<input
								onChange={(e) => {
									setEmailInput(e.target.value)
									setError(undefined)
								}}
								value={emailInput}
							/>
							{!isSignedInWithGoogle && (
								<Button type="tertiary" onClick={openReauth}>
									<span>Update email</span>
								</Button>
							)}
							<p className="logOut pt-4" onClick={logoutUser}>
								Log out
							</p>
							{error && <div className="highlighted error">{errorToText[error]}</div>}
						</div>
						<div className="pro">
							{!hasPremiumAccess && (
								<Premium
									closeParentModal={closeModal}
									setLoginModal={(open: boolean) => {
										setLoginModalOpen(open)
									}}
									shorten
									annualPlan={annualPlan}
									setAnnualPlan={setAnnualPlan}
								/>
							)}
							{hasPremiumAccess && (
								<>
									<h5 className="header-md">
										You're on the <span className=" highlighted">Premium</span> plan
									</h5>
									{user.subscriptionStatus && <div className="subscriptionStatus">{user.subscriptionStatus}</div>}
									<Button type="tertiary" className="managePlan mt-6" onClick={createPortalSession}>
										<span>Manage Billing</span>
									</Button>
								</>
							)}
						</div>
					</div>
					{user.hasPaidAddon && (
						<>
							<div className="dividingBorder" />
							<div className="humanizerUpgrade">
								<div className="descriptionSection">
									<div>AI Humanizer and Detector enabled.</div>
									<div className="providedBy">
										Powered by <a href="https://www.the-ghost-ai.com/">Ghost AI</a>
									</div>
								</div>
								<Button type="secondary" htmlType="submit" onClick={disableHumanizer}>
									<span>Disable</span>
								</Button>
							</div>
						</>
					)}
				</>
			</Modal>
		</>
	)
}

export default AccountModal
