import { useState } from 'react'
import Modal from '../../components-landing/ui/modal'
import { Info } from 'lucide-react'

const StrengthExplainerModal = () => {
	const [modalOpen, setModalOpen] = useState(false)

	return (
		<>
			<Info onClick={() => setModalOpen(true)} className="cursor-pointer" />
			<Modal open={modalOpen} setOpen={setModalOpen} className="max-w-2xl">
				<div className="flex flex-col gap-3 p-5 items-center text-center">
					<div className="md:text-3xl text-2xl font-semibold">Humanization Strength</div>
					<div className="border-b border-gray-500 w-full"></div>
					<div className="text-xl font-semibold">Light</div>
					Ideal for bloggers, this mode emphasizes high readability and flawless grammar.
					<div className="text-xl font-semibold">Medium</div>
					Perfect for students looking to bypass Turnitin and other academic AI detectors. Balances AI detection evasion
					with optimal readability.
					<div className="text-xl font-semibold">Strong</div>
					Use to bypass all AI detection. Prioritizes undetectable content over readability for industry-leading bypass
					rates.
				</div>
			</Modal>
		</>
	)
}

export default StrengthExplainerModal
