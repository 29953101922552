import { CONSTANTS } from '../constants'
import { useIsAuthenticated } from './authenticated'
import { useCookieUser } from './cookieUser'
import * as Sentry from '@sentry/react'

const useEnterApp = ({ history, setLoading }: { history: any; setLoading?: (loading: boolean) => void }) => {
	const isAuthenticated = useIsAuthenticated()
	const { setUserIDCookie } = useCookieUser()

	const createUser = (url?: string) => {
		setLoading?.(true)
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				secondaryClient: true,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/user/retrieve/', requestOptions)
			.then((res) => res.json())
			.then((result: any) => {
				if (result) {
					setUserIDCookie(result.id)
					history.push(url ?? CONSTANTS.DOC_PATH)
				}
				setLoading?.(false)
			})
			.catch((err) => Sentry.captureException(err))
	}

	function enterApp(url?: string) {
		if (isAuthenticated) {
			history.push(CONSTANTS.DOC_PATH)
		} else {
			createUser(url)
		}
	}
	return { enterApp, createUser }
}

export default useEnterApp
