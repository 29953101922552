import { useHistory } from 'react-router-dom'
import useEnterApp from '../../hooks/enterApp'
import GetStartedButton from '../get-started'

const TryFree = ({ variant = 'primary' }: { variant?: 'primary' | 'humanizer' }) => {
	const history = useHistory()
	const { enterApp } = useEnterApp({ history })

	const cta = {
		primary: 'Get Started',
		humanizer: 'Sign Up Now',
	}

	const redirectUrl = {
		primary: '/choose',
		humanizer: '/humanizer',
	}

	const header = {
		primary: 'Start humanizing with Clarify today!',
		humanizer: 'Save Time and Humanize Your AI Content',
	}

	const description = {
		primary: 'Join over 3 million students and writers who write and humanize using Clarify.',
		humanizer: 'Let us handle your AI humanizing needs—write with Clarify AI and free up your schedule today!',
	}

	return (
		<div className="mx-auto max-w-4xl text-center">
			<h2 className="mt-4 text-4xl sm:text-6xl/tight text-gray-900">{header[variant]}</h2>
			<div className="my-6 text-xl text-gray-700">{description[variant]}</div>
			<GetStartedButton redirectUrl={redirectUrl[variant]} cta={cta[variant]} />
		</div>
	)
}

export default TryFree
