import React from 'react'
import { FAQ } from '../../lib/faqs'

interface FAQSchemaProps {
	faqs: FAQ[]
}

export default function FAQSchema({ faqs }: FAQSchemaProps) {
	const schemaData = {
		'@context': 'https://schema.org',
		'@type': 'FAQPage',
		mainEntity: faqs.map((faq) => ({
			'@type': 'Question',
			name: faq.question,
			acceptedAnswer: {
				'@type': 'Answer',
				text: faq.answer,
			},
		})),
	}

	return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }} />
}
