import { useAtom } from 'jotai'
import { accountModalAtom } from '../../Pages/Create/Create'
import { CONSTANTS } from '../../constants'
import { useProcessedSubtopics } from '../../Pages/Create/hooks'
import { useFreeWordLimit } from '../../hooks/featureFlags'
import { selectDocumentState } from '../../redux/docSlice'
import { DocumentState } from '../../redux/types'
import { useSelector } from 'react-redux'
import { Button } from '../../components-landing/ui/button'

const DocumentBlur = ({ docId }: { docId: string }) => {
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))
	const [, setAccountModalState] = useAtom(accountModalAtom)
	const freeWordLimit = useFreeWordLimit()
	const processedSubtopics = useProcessedSubtopics()

	return (
		<div
			className="overflow-hidden relative md:h-96 h-[500px]"
			onClick={() => {
				setAccountModalState((prev) => ({ ...prev, subscriptionModalOpen: true }))
			}}
		>
			<div className="blur-sm pl-1">
				{documentState.userWriterSelections?.wordCount > CONSTANTS.TOPIC_GENERATION_BREAKPOINT && (
					<div className="text-3xl leading-loose">
						{freeWordLimit > 250 ? processedSubtopics[2] : processedSubtopics[1]}
					</div>
				)}
				<div className="text-xl leading-loose mt-2 h-48 select-none">
					Lorem ipsum odor amet, consectetuer adipiscing elit. Facilisis semper velit curabitur dignissim augue quisque
					suspendisse aenean. Ipsum eu proin commodo a dolor ad maecenas et. Sed aptent purus platea leo est conubia
					dictum nascetur? Vivamus dapibus consequat nibh pulvinar aenean. Non vestibulum turpis gravida morbi porta
					hendrerit fusce. Vivamus dapibus consequat nibh pulvinar aenean. Non vestibulum turpis gravida morbi porta
					hendrerit fusce. Vivamus dapibus consequat nibh pulvinar aenean. Non vestibulum turpis gravida morbi porta
					hendrerit fusce.
				</div>
			</div>
			<div className="bg-secondary w-full absolute bottom-0 flex items-center justify-center flex-col gap-3 py-9 px-3">
				<div className="text-2xl font-semibold text-center text-white">
					Unlock your full writing and go <span className=" font-normal">AI-undetectable</span>
				</div>
				<div className="text-lg text-gray-100 max-w-[650px] text-center">
					Get access to your entire document, unlimited humanizing, unlimited AI generations, and autocomplete.
				</div>
				<Button
					size="xl"
					variant="outline"
					className="bg-white text-secondary"
					onClick={() => setAccountModalState((prev) => ({ ...prev, subscriptionModalOpen: true }))}
				>
					Upgrade Now
				</Button>
			</div>
		</div>
	)
}

export default DocumentBlur
