import { useHistory } from 'react-router-dom'
import { Button } from '../components-landing/ui/button'
import Footer from '../Components/Footer'
import {
	Clock,
	Eraser,
	GraduationCap,
	ListTodo,
	MousePointerClick,
	Pencil,
	PencilLine,
	Radar,
	Rocket,
	ScanText,
	ShieldCheck,
	Star,
	ThumbsUp,
	User,
	UsersRound,
	WandSparkles,
} from 'lucide-react'
import { Avatar, AvatarImage } from '../components-landing/ui/avatar'
import User4 from '../assets/images/user4.jpeg'
import User5 from '../assets/images/user5.jpeg'
import User6 from '../assets/images/user6.jpeg'
import { Editor } from './Humanizer/editor'
import { useInView } from 'react-intersection-observer'
import { Badge } from '../components-landing/ui/badge'
import Reviews from '../components-landing/reviews'
import FAQ from '../components-landing/faq'
import TryFree from '../components-landing/commons/try-free'
import { useEffect, useMemo, useState } from 'react'
import UpgradeModals from '../Components/Modals/Upgrade'
import GetStartedModal from '../Components/Modals/Login/GetStarted'
import { useAuth } from '../contexts/Auth'
import { usePostHog } from 'posthog-js/react'
import { useLocalStorage } from '../helpers/utility'
import { zarazLog } from '../helpers/analytics'

const HumanizerLanding = () => {
	const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
	const [getStartedModalOpen, setGetStartedModalOpen] = useState(false)
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const [bannerOpen] = useLocalStorage('bannerOpen', false)

	const history = useHistory()
	const posthog = usePostHog()
	const { currentUser } = useAuth() as any
	const { ref, inView } = useInView({
		threshold: 0,
	})

	useEffect(() => {
		posthog.capture('page-viewed', { page: 'humanizer' })
		window.scrollTo(0, 0)
		zarazLog('PageView')
	}, [])

	const accountModals = useMemo(
		() => (
			<>
				<UpgradeModals
					subscriptionModalOpen={subscriptionModalOpen}
					loginModalOpen={loginModalOpen}
					setSubscriptionModalOpen={(open: boolean) => {
						setSubscriptionModalOpen(open)
					}}
					setLoginModalOpen={(open: boolean) => {
						setLoginModalOpen(open)
					}}
					premiumPath={true}
					noMoreCredits
				/>
				<GetStartedModal
					setModalOpen={setGetStartedModalOpen}
					modalOpen={getStartedModalOpen}
					signInWithEmail={() => {
						setLoginModalOpen(true)
						setGetStartedModalOpen(false)
					}}
				/>
			</>
		),
		[subscriptionModalOpen, loginModalOpen, getStartedModalOpen]
	)

	return (
		<>
			{accountModals}
			<div className="mx-auto flex flex-col ">
				<div className="w-full mx-auto flex flex-col gap-12 pb-24">
					<div className="relative">
						<div className="w-screen h-screen bg-gradient-to-b from-white via-[#ba85eb] to-white absolute bottom-[-80px] opacity-30 z-[-10]" />
						<div className={`mx-auto  w-full max-w-screen-xl px-4 md:mt-40 ${bannerOpen ? 'mt-44' : 'mt-24'}`}>
							{/* <div className="text-center font-bold text-purple-600 tracking-wider">AI HUMANIZER</div> */}
							<div className="mx-auto w-full max-w-4xl text-center mt-1">
								<h1 className="md:text-5xl text-4xl leading-tight sm:text-6xl font-[500] text-gray-900">
									Transform robotic AI text into human-like writing with our AI Humanizer
								</h1>
								<p className="mt-3 text-xl text-gray-700">
									Turn AI-written text into natural, human-like content with our AI Humanizer. Clarify AI helps you
									easily bypass AI detectors like Turnitin, Originality, and GPTZero, all while enhancing tone and flow.
								</p>
								<Button
									size="xl"
									className="group mt-2 md:text-2xl whitespace-pre-wrap"
									onClick={() => {
										if (!currentUser) {
											setGetStartedModalOpen(true)
										} else {
											history.push('/humanizer')
										}
									}}
								>
									<MousePointerClick className="mr-2" />
									Start Humanizing Now
									<span className="ml-1 text-base text-white transition-all group-hover:text-white">- it's free</span>
								</Button>
								<div className="mt-4 flex flex-row items-center justify-center gap-2">
									<div className="flex flex-row items-center -space-x-2">
										<Avatar className="h-8 w-8 outline outline-2 outline-white">
											<AvatarImage src={User4} alt="User profile picture" />
										</Avatar>
										<Avatar className="h-8 w-8 outline outline-2 outline-white">
											<AvatarImage src={User5} alt="User profile picture" />
										</Avatar>
										<Avatar className="h-8 w-8 outline outline-2 outline-white">
											<AvatarImage src={User6} alt="User profile picture" />
										</Avatar>
									</div>
									<div className="text-gray-700">Join 3,000,000+ writers</div>
								</div>
							</div>
							<div
								className={`mt-10 hidden md:block transform transition-all duration-700 ${
									inView ? 'opacity-100 scale-100' : 'opacity-0 scale-90'
								}`}
								ref={ref}
							>
								<Editor />
							</div>
							<div className="mt-10 block md:hidden">
								<video src="/humanizer.mp4" className="rounded-xl shadow" autoPlay />
							</div>
						</div>
					</div>
				</div>
				<div className="py-24 bg-gray-50 px-1">
					<div className="max-w-6xl mx-auto flex flex-col items-center gap-2">
						<div className="flex gap-2 text-secondary justify-center items-center">
							{/* <GraduationCap size={48} /> */}
							<div className="text-4xl font-semibold">Trusted by Students Globally</div>
						</div>
						<div className="text-center text-gray-700 max-w-3xl text-lg">
							Thousands of students and content writers around the world use our AI humanizer to deliver original,
							human-like content that bypasses the leading plagiarism and AI detectors.
						</div>
						<div className="grid md:grid-cols-3 w-full rounded-xl gap-0.5 overflow-hidden mt-8">
							<div className="flex flex-col gap-2 p-4  justify-center items-center">
								<div className="text-3xl font-semibold text-secondary">800,000+</div>
								<div className="text-sm text-gray-700">
									<Clock className="inline-block mr-1" size={15} />
									<div className="inline font-semibold">Hours saved writing</div>
								</div>
							</div>
							<div className="flex flex-col gap-2 p-4 justify-center items-center">
								<div className="text-3xl font-semibold text-secondary">3,000,000+</div>
								<div className="text-sm text-gray-700">
									<UsersRound className="inline-block mr-1" size={15} />
									<div className="inline font-semibold">Writers using Clarify</div>
								</div>
							</div>
							<div className="flex flex-col gap-2 p-4  justify-center items-center">
								<div className="text-3xl font-semibold text-secondary">13,000,000+</div>
								<div className="text-sm text-gray-700">
									<ListTodo className="inline-block mr-1" size={15} />
									<div className="inline font-semibold">AI Humanizations</div>
								</div>
							</div>
						</div>
						{/* <div className="text-success-default text-2xl font-medium w-full text-center mt-8">
							<ThumbsUp className="inline-block mb-1" /> Join the thousands of students and content writers using our AI
							humanizer today!
						</div> */}
					</div>
				</div>
				<div className="text-center py-24">
					<div className="mx-auto max-w-4xl px-1">
						{/* <Badge
							size="lg"
							className="gap-2 bg-secondary bg-opacity-30 font-bold uppercase text-primary-800 
						bg-gradient-to-r from-primary-200 to-primary-400"
						>
							<WandSparkles />
							Why Our AI Humanizer?
						</Badge> */}
						<h2 className="mt-3 text-3xl sm:text-5xl text-gray-900">An All-in-One AI Humanizer Tool</h2>
						<p className="mt-6 text-lg text-gray-700 mx-auto max-w-3xl">
							Streamline your content creation from start to finish with our AI humanizer—crafting human-sounding
							content has never been easier.
						</p>
					</div>
					<div className="mx-auto max-w-6xl grid md:grid-cols-2 gap-8 mt-16 px-1">
						{[
							{
								icon: <PencilLine size={20} />,
								title: 'Humanization',
								description:
									'Elevate your content with our AI Humanizer, which transforms dull, mechanical text into engaging, human-like content that resonates with readers.',
							},
							{
								icon: <ScanText size={20} />,
								title: 'Plagiarism',
								description:
									"Ensure originality with Clarify's AI humanizer plagiarism detection—our detector scans your text against an extensive database, guaranteeing that every piece of content you produce is 100% unique.",
							},
							{
								icon: <Radar size={20} />,
								title: 'Detector Bypass',
								description:
									'Our AI humanizer is built to produce human-sounding content that bypasses the leading AI detections on the market.',
							},
							{
								icon: <Star size={20} />,
								title: 'Content Quality',
								description:
									'Create engaging, well-crafted AI humanized content that connects with readers and highlights your insights.',
							},
						].map((feature, index) => (
							<div
								key={index}
								className="flex flex-col gap-4 px-3 py-6 border border-gray-300 bg-gray-50 rounded-xl hover:border-secondary transition-all"
							>
								<div className="text-lg flex items-center gap-2 font-semibold">
									<div className="p-2 bg-secondary rounded-full text-white">{feature.icon}</div> {feature.title}
								</div>
								<div className="text-gray-800 text-left">{feature.description}</div>
							</div>
						))}
					</div>
				</div>
				<div className="py-24 bg-gray-50 px-1">
					<div className="max-w-6xl mx-auto">
						<Reviews />
					</div>
				</div>
				<div className="pb-24 px-1">
					<div className="max-w-6xl mx-auto">
						<FAQ variant="humanizer" />
					</div>
				</div>
				<div className="py-24 bg-gray-50">
					<div className="max-w-6xl mx-auto">
						<TryFree variant="humanizer" />
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default HumanizerLanding
