import { useEffect, useState } from 'react'
import { CONSTANTS } from '../constants'
import { ProcessedChunkResponse } from '../redux/types'

//Given a stream and handler functions, turns the chunks from the stream into content to update the user's essay
export async function streamToContentUpdate(
	reader: ReadableStreamDefaultReader<Uint8Array>,
	handleDone: () => void,
	handleSetContent: (content: string) => void,
	handleScroll: () => void
) {
	let buffer = ''

	while (reader) {
		const { done, value } = await reader.read()
		if (done) {
			handleDone()
			return
		}

		//Turn chunk into complete content
		buffer += Buffer.from(value).toString('utf-8')
		const processedChunkResponse: ProcessedChunkResponse = parseChunk(buffer)
		buffer = processedChunkResponse.buffer

		if (processedChunkResponse.parsed.length > 0) {
			handleSetContent(processedChunkResponse.parsed[0].content)
		}

		handleScroll()
	}
}

function splitJSONObjects(objs: string[]) {
	return objs.flatMap((obj) => {
		// List to hold the separated JSON strings
		let separated = []
		let braceCount = 0
		let currentObj = ''

		for (let i = 0; i < obj.length; i++) {
			const char = obj[i]

			// Increment or decrement braceCount
			if (char === '{') {
				braceCount++
			} else if (char === '}') {
				braceCount--
			}

			// Add character to current object string
			currentObj += char

			// If braceCount is zero and current object string is not empty, it's a complete JSON object
			if (braceCount === 0 && currentObj.trim() !== '') {
				separated.push(currentObj)
				currentObj = '' // Reset for the next object
			}
		}

		return separated
	})
}

//Takes a chunk as a string, parses it to json objects, and returns the parsed objects and any leftover buffer
export function parseChunk(buffer: string): ProcessedChunkResponse {
	//Separate into array of json objects; leave incomplete json in the buffer
	let objs: string[] = []
	for (let i = 0; i < buffer.length; i++) {
		if (buffer[i] === '}') {
			objs.push(buffer.substring(0, i + 1))
			buffer = buffer.substring(i + 1)
		}
	}
	objs = splitJSONObjects(objs)

	//Sort by timestamp
	const parsed = objs.map((json) => JSON.parse(json.trim()))
	parsed.sort((a, b) => {
		const date1 = new Date(a.timestamp)
		const date2 = new Date(b.timestamp)
		return date2.getTime() - date1.getTime()
	})
	return { buffer: buffer, parsed: parsed }
}

export function isValidEmail(email: string) {
	//At least one character before and after @, domain contains at least one period, only one @ present
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

//Takes tiptap html and converts it to a string; used for copying a normal string
export function htmlToString(htmlStr: string): string {
	if (!htmlStr || htmlStr.length === 0) return ''
	htmlStr = htmlStr.replace(/<br\s*\/?>/gi, '\n') //Replace <br> with \n
	htmlStr = htmlStr.replace(/<p[^>]*>/gi, '') // remove any opening <p> tags
	htmlStr = htmlStr.replace(/<\/p[^>]*>/gi, '\n\n') //Replace </p> tags with \n\n

	//Remove all other html tags ignoring style
	let div = document.createElement('div')
	div.innerHTML = htmlStr
	return div.textContent || div.innerText || ''
}

export function htmlToStringBesidesH6(htmlStr: string): string {
	if (!htmlStr || htmlStr.length === 0) return ''

	// Replace <br> with \n
	htmlStr = htmlStr.replace(/<br\s*\/?>/gi, '\n')

	// Remove any opening <p> tags
	htmlStr = htmlStr.replace(/<p[^>]*>/gi, '')

	// Replace </p> tags with \n\n
	htmlStr = htmlStr.replace(/<\/p[^>]*>/gi, '\n\n')

	// Create a div element to parse the HTML
	let div = document.createElement('div')
	div.innerHTML = htmlStr

	// Recursive function to remove all tags except <h6>
	function removeTagsExceptH6(node: Node): void {
		// If the node is an element node
		if (node.nodeType === Node.ELEMENT_NODE) {
			let el = node as HTMLElement
			// If the node is not an <h6> tag
			if (el.tagName.toLowerCase() !== 'h6') {
				// Process child nodes recursively
				let childNodes = Array.from(el.childNodes)
				childNodes.forEach(removeTagsExceptH6)

				// Replace the element with its child nodes
				let parent = el.parentNode
				if (parent) {
					while (el.firstChild) {
						parent.insertBefore(el.firstChild, el)
					}
					parent.removeChild(el)
				}
			} else {
				// If it is an <h6> tag, process its child nodes
				let childNodes = Array.from(el.childNodes)
				childNodes.forEach(removeTagsExceptH6)
			}
		} else if (node.nodeType === Node.TEXT_NODE) {
			// Do nothing for text nodes
		} else if (node.nodeType === Node.COMMENT_NODE) {
			// Remove comment nodes
			node.parentNode?.removeChild(node)
		} else {
			// Remove other types of nodes
			node.parentNode?.removeChild(node)
		}
	}

	// Start processing from the div's child nodes
	let childNodes = Array.from(div.childNodes)
	childNodes.forEach(removeTagsExceptH6)

	// Return the innerHTML, which now contains only text and <h6> tags
	return div.innerHTML
}

//Takes a string response from openAI and converts it to HTML for tiptap to use
export function completionToHTMLString(str: string): string {
	const paragraphs = str.split('\n\n')
	const wrappedParagraphs = paragraphs.map((paragraph, index) => {
		if (index !== paragraphs.length - 1) return `<p>${paragraph}</p><p></p>`
		return `<p>${paragraph}</p>`
	})
	return wrappedParagraphs.join('')
}

// Hook to persist state to local storage
// https://usehooks.com/useLocalStorage/
export function useLocalStorage<T>(key: string, initialValue: T) {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState<T>(() => {
		if (typeof window === 'undefined') {
			return initialValue
		}
		try {
			// Get from local storage by key
			const item = window.localStorage.getItem(key)
			// Parse stored json or if none return initialValue
			return item ? JSON.parse(item) : initialValue
		} catch (error) {
			// If error also return initialValue
			console.log(error)
			return initialValue
		}
	})
	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = (value: T | ((val: T) => T)) => {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore = value instanceof Function ? value(storedValue) : value
			// Save state
			setStoredValue(valueToStore)
			// Save to local storage
			if (typeof window !== 'undefined') {
				window.localStorage.setItem(key, JSON.stringify(valueToStore))
			}
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.log(error)
		}
	}
	return [storedValue, setValue] as const
}

// Hook to check if user on either the create or document page
export function useIsInApp() {
	const [isInApp, setIsInApp] = useState<boolean>(
		window.location.pathname.includes(CONSTANTS.CREATE_PATH) || window.location.pathname.includes(CONSTANTS.DOC_PATH)
	)

	useEffect(() => {
		setIsInApp(
			window.location.pathname.includes(CONSTANTS.CREATE_PATH) || window.location.pathname.includes(CONSTANTS.DOC_PATH)
		)
		//Needs to update when pathname changes, otherwise navbar says GO TO APP instead of Account
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location.pathname])
	return isInApp
}
