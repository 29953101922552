import { useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import { selectUser } from '../redux/systemSlice'
import { useAuth } from '../contexts/Auth'

const useHumanizerCredits = () => {
	const { currentUser } = useAuth() as any
	const user = useSelector(selectUser)
	const [cookies, setCookies] = useCookies(['creditsConsumed'])
	const [consumed, setConsumed] = useState<number>(() => cookies.creditsConsumed ?? 0)

	// Update localStorage whenever 'consumed' changes
	useEffect(() => {
		setCookies('creditsConsumed', consumed)
	}, [consumed, setCookies])

	// Use functional updates to avoid stale state
	const consumeCredits = (numCredits: number) => {
		setConsumed((prev) => prev + numCredits)
	}

	const addCredits = (numCredits: number) => {
		setConsumed((prev) => prev - numCredits)
	}

	const creditsAvailable = useMemo(() => {
		if (user.isOnFreeTrial) {
			return 600 + 300 - (cookies.creditsConsumed ?? 0)
		}
		if (currentUser) {
			return 300 - (cookies.creditsConsumed ?? 0)
		}
		return 0
	}, [cookies.creditsConsumed, user.isOnFreeTrial, currentUser])

	return {
		creditsConsumed: consumed,
		creditsAvailable: Math.max(0, creditsAvailable),
		consumeCredits,
		addCredits,
	}
}

export default useHumanizerCredits
