import useIsPremium from '../hooks/useIsPremium'
import useHumanizerCredits from '../hooks/useHumanizerCredits'
import { useSelector } from 'react-redux'
import { selectUser } from '../redux/systemSlice'

const ToolCredits = () => {
	const { creditsAvailable } = useHumanizerCredits()
	const isPremium = useIsPremium()
	const user = useSelector(selectUser)
	const pathname = window.location.pathname

	if ((isPremium && !user.isOnFreeTrial) || pathname.includes('detector')) {
		return null
	}

	return (
		<div className="flex gap-1">
			<div className="text-gray-600">Free credits: </div>
			<div className="font-semibold">{creditsAvailable} words</div>
		</div>
	)
}

export default ToolCredits
