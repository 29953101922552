import React from 'react'

const TermsOfService = () => (
	<div className="max-w-5xl mx-auto my-24">
		<h1 className="text-2xl font-bold">Terms of Service</h1>
		<div className="text-md">Last Updated: September 10, 2024</div>

		<div className="text-md mt-4">We are Clarify AI ("Company," "we," "us," "our").</div>
		<div className="text-md mt-4">
			We operate the website{' '}
			<a href="https://clarify.ai" className="text-blue-600">
				https://clarify.ai
			</a>{' '}
			(the "Site"), as well as any other related products and services that refer or link to these legal terms (the
			"Legal Terms") (collectively, the "Services").
		</div>
		<div className="text-md mt-4">We provide text humanization services with AI products.</div>
		<div className="text-md mt-4">
			You can contact us by email at{' '}
			<a href="mailto:support@clarify.ai" className="text-blue-600">
				support@clarify.ai
			</a>
		</div>
		<div className="text-md mt-4">
			These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an
			entity ("you"), and Clarify AI, concerning your access to and use of the Services. You agree that by accessing the
			Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH
			ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE
			IMMEDIATELY.
		</div>
		<div className="text-md mt-4">
			Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby
			expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or
			modifications to these Legal Terms at any time and for any reason. We will alert you about any changes by updating
			the "Last updated" date of these Legal Terms, and you waive any right to receive specific notice of each such
			change. It is your responsibility to periodically review these Legal Terms to stay informed of updates. You will
			be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised
			Legal Terms by your continued use of the Services after the date such revised Legal Terms are posted.
		</div>
		<div className="text-md mt-4">
			All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the
			permission of, and be directly supervised by, their parent or guardian to use the Services. If you are a minor,
			you must have your parent or guardian read and agree to these Legal Terms prior to you using the Services.
		</div>
		<div className="text-md mt-4">We recommend that you print a copy of these Legal Terms for your records.</div>

		<h2 className="text-xl font-bold mt-6">1. OUR SERVICES</h2>
		<div className="text-md mt-2">
			The information provided when using the Services is not intended for distribution to or use by any person or
			entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or
			which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those
			persons who choose to access the Services from other locations do so on their own initiative and are solely
			responsible for compliance with local laws, if and to the extent local laws are applicable.
		</div>
		<div className="text-md mt-2">
			The Services are not tailored to comply with industry-specific regulations (Health Insurance Portability and
			Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions
			would be subjected to such laws, you may not use the Services. You may not use the Services in a way that would
			violate the Gramm-Leach-Bliley Act (GLBA).
		</div>
		<h2 className="text-xl font-bold mt-6">2. INTELLECTUAL PROPERTY RIGHTS</h2>

		<h3 className="text-lg font-semibold mt-4">Our intellectual property</h3>
		<div className="text-md mt-2">
			We are the owner or the licensee of all intellectual property rights in our Services, including all source code,
			databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services
			(collectively, the "Content"), as well as the trademarks, service marks, and logos contained therein (the
			"Marks").
		</div>
		<div className="text-md mt-2">
			Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property
			rights and unfair competition laws) and treaties in the United States and around the world.
		</div>
		<div className="text-md mt-2">
			The Content and Marks are provided in or through the Services "AS IS" for your personal, non-commercial use or
			internal business purpose only.
		</div>

		<h3 className="text-lg font-semibold mt-4">Your use of our Services</h3>
		<div className="text-md mt-2">
			Subject to your compliance with these Legal Terms, including the "PROHIBITED ACTIVITIES" section below, we grant
			you a non-exclusive, non-transferable, revocable license to:
		</div>
		<ul className="list-disc list-inside text-md mt-2">
			<li className="text-[16px]">access the Services; and</li>
			<li className="text-[16px]">
				download or print a copy of any portion of the Content to which you have properly gained access, solely for your
				personal, non-commercial use or internal business purpose.
			</li>
		</ul>
		<div className="text-md mt-2">
			Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks
			may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated,
			transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without
			our express prior written permission.
		</div>
		<div className="text-md mt-2">
			If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere
			in our Legal Terms, please address your request to:{' '}
			<a href="mailto:support@clarify.ai" className="text-blue-600">
				support@clarify.ai
			</a>
			. If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content,
			you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright
			or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.
		</div>
		<div className="text-md mt-2">
			We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.
		</div>
		<div className="text-md mt-2">
			Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your
			right to use our Services will terminate immediately.
		</div>

		<h3 className="text-lg font-semibold mt-4">Your submissions and contributions</h3>
		<div className="text-md mt-2">
			Please review this section and the "PROHIBITED ACTIVITIES" section carefully prior to using our Services to
			understand the (a) rights you give us and (b) obligations you have when you post or upload any content through the
			Services.
		</div>
		<h4 className="text-md font-semibold mt-4">Submissions:</h4>
		<div className="text-md mt-2">
			By directly sending us any question, comment, suggestion, idea, feedback, or other information about the Services
			("Submissions"), you agree to assign to us all intellectual property rights in such Submission. You agree that we
			shall own this Submission and be entitled to its unrestricted use and dissemination for any lawful purpose,
			commercial or otherwise, without acknowledgment or compensation to you.
		</div>
		<h4 className="text-md font-semibold mt-4">Contributions:</h4>
		<div className="text-md mt-2">
			The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and
			other functionality during which you may create, submit, post, display, transmit, publish, distribute, or
			broadcast content and materials to us or through the Services, including but not limited to text, writings, video,
			audio, photographs, music, graphics, comments, reviews, rating suggestions, personal information, or other
			material ("Contributions"). Any Submission that is publicly posted shall also be treated as a Contribution.
		</div>
		<div className="text-md mt-2">
			You understand that Contributions may be viewable by other users of the Services and possibly through third-party
			websites.
		</div>
		<div className="text-md mt-2">
			When you post Contributions, you grant us a license (including use of your name, trademarks, and logos): By
			posting any Contributions, you grant us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
			transferable, royalty-free, fully-paid, worldwide right, and license to: use, copy, reproduce, distribute, sell,
			resell, publish, broadcast, retitle, store, publicly perform, publicly display, reformat, translate, excerpt (in
			whole or in part), and exploit your Contributions (including, without limitation, your image, name, and voice) for
			any purpose, commercial, advertising, or otherwise, to prepare derivative works of, or incorporate into other
			works, your Contributions, and to sublicense the licenses granted in this section. Our use and distribution may
			occur in any media formats and through any media channels.
		</div>
		<div className="text-md mt-2">
			This license includes our use of your name, company name, and franchise name, as applicable, and any of the
			trademarks, service marks, trade names, logos, and personal and commercial images you provide.
		</div>
		<div className="text-md mt-2">
			You are responsible for what you post or upload: By sending us Submissions and/or posting Contributions through
			any part of the Services:
		</div>
		<ul className="list-disc list-inside text-md mt-2">
			<li className="text-[16px]">
				Confirm that you have read and agree with our "PROHIBITED ACTIVITIES" and will not post, send, publish, upload,
				or transmit through the Services any Submission nor post any Contribution that is illegal, harassing, hateful,
				harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or group, sexually
				explicit, false, inaccurate, deceitful, or misleading;
			</li>
			<li className="text-[16px]">
				To the extent permissible by applicable law, waive any and all moral rights to any such Submission and/or
				Contribution;
			</li>
			<li className="text-[16px]">
				Warrant that any such Submission and/or Contributions are original to you or that you have the necessary rights
				and licenses to submit such Submissions and/or Contributions and that you have full authority to grant us the
				above-mentioned rights in relation to your Submissions and/or Contributions; and
			</li>
			<li className="text-[16px]">
				Warrant and represent that your Submissions and/or Contributions do not constitute confidential information.
			</li>
		</ul>
		<div className="text-md mt-2">
			You are solely responsible for your Submissions and/or Contributions and you expressly agree to reimburse us for
			any and all losses that we may suffer because of your breach of (a) this section, (b) any third party’s
			intellectual property rights, or (c) applicable law.
		</div>
		<div className="text-md mt-2">
			We may remove or edit your Content: Although we have no obligation to monitor any Contributions, we shall have the
			right to remove or edit any Contributions at any time without notice if in our reasonable opinion we consider such
			Contributions harmful or in breach of these Legal Terms. If we remove or edit any such Contributions, we may also
			suspend or disable your account and report you to the authorities.
		</div>

		<h2 className="text-xl font-bold mt-6">Copyright infringement</h2>
		<div className="text-md mt-2">
			We respect the intellectual property rights of others. If you believe that any material available on or through
			the Services infringes upon any copyright you own or control, please immediately refer to the "COPYRIGHT
			INFRINGEMENTS" section below.
		</div>

		<h2 className="text-xl font-bold mt-6">3. USER REPRESENTATIONS</h2>
		<div className="text-md mt-2">By using the Services, you represent and warrant that:</div>
		<ul className="list-disc list-inside text-md mt-2">
			<li className="text-[16px]">
				All registration information you submit will be true, accurate, current, and complete;
			</li>
			<li className="text-[16px]">
				You will maintain the accuracy of such information and promptly update such registration information as
				necessary;
			</li>
			<li className="text-[16px]">You have the legal capacity and you agree to comply with these Legal Terms;</li>
			<li className="text-[16px]">
				You are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental
				permission to use the Services;
			</li>
			<li className="text-[16px]">
				You will not access the Services through automated or non-human means, whether through a bot, script or
				otherwise;
			</li>
			<li className="text-[16px]">You will not use the Services for any illegal or unauthorized purpose; and</li>
			<li className="text-[16px]">Your use of the Services will not violate any applicable law or regulation.</li>
		</ul>
		<div className="text-md mt-2">
			If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to
			suspend or terminate your account and refuse any and all current or future use of the Services (or any portion
			thereof).
		</div>
		<div className="text-md mt-2">
			Affiliates agree to adhere to all terms outlined in the Affiliate Program section, in addition to complying with
			all other provisions of these Terms of Service.
		</div>

		<h2 className="text-xl font-bold mt-6">4. USER REGISTRATION</h2>
		<div className="text-md mt-2">
			You may be required to register to use the Services. You agree to keep your password confidential and will be
			responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a
			username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or
			otherwise objectionable.
		</div>

		<h2 className="text-xl font-bold mt-6">5. PURCHASES AND PAYMENT</h2>
		<div className="text-md mt-2">We accept the following forms of payment:</div>
		<ul className="list-disc list-inside text-md mt-2">
			<li className="text-[16px]">Visa</li>
			<li className="text-[16px]">Discover</li>
			<li className="text-[16px]">American Express</li>
			<li className="text-[16px]">Mastercard</li>
		</ul>
		<div className="text-md mt-2">
			You agree to provide current, complete, and accurate purchase and account information for all purchases made via
			the Services. You further agree to promptly update account and payment information, including email address,
			payment method, and payment card expiration date, so that we can complete your transactions and contact you as
			needed. Sales tax will be added to the price of purchases as deemed required by us. We may change prices at any
			time. All payments shall be the currency listed in checkout.
		</div>
		<div className="text-md mt-2">
			You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping fees, and
			you authorize us to charge your chosen payment provider for any such amounts upon placing your order. If your
			order is subject to recurring charges, then you consent to our charging your payment method on a recurring basis
			without requiring your prior approval for each recurring charge, until such time as you cancel the applicable
			order. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or
			received payment.
		</div>
		<div className="text-md mt-2">
			We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or
			cancel quantities purchased per person, per household, or per order. These restrictions may include orders placed
			by or under the same customer account, the same payment method, and/or orders that use the same billing or
			shipping address. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed
			by dealers, resellers, or distributors.
		</div>

		<h2 className="text-xl font-bold mt-6">6. FREE TRIAL</h2>
		<div className="text-md mt-2">There is no free trial.</div>

		<h2 className="text-xl font-bold mt-6">7. CANCELLATION</h2>
		<div className="text-md mt-2">
			All purchases are non-refundable. You can cancel your subscription by logging into your account, going to our
			settings page, and clicking on the billing section. From there, you can cancel your subscription. Your
			cancellation will take effect at the end of the current paid term, and you will continue to have access to your
			services without refund.
		</div>
		<div className="text-md mt-2">
			If you are unsatisfied with our Services, please email us at{' '}
			<a href="mailto:support@clarify.ai" className="text-blue-600">
				support@clarify.ai
			</a>
			.
		</div>

		<h2 className="text-xl font-bold mt-6">8. PROHIBITED ACTIVITIES</h2>
		<div className="text-md mt-2">
			You may not access or use the Services for any purpose other than that for which we make the Services available.
			The Services may not be used in connection with any commercial endeavors except those that are specifically
			endorsed or approved by us.
		</div>
		<div className="text-md mt-2">As a user of the Services, you agree not to:</div>
		<ul className="list-disc list-inside text-md mt-2">
			<li className="text-[16px]">
				Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a
				collection, compilation, database, or directory without written permission from us.
			</li>
			<li className="text-[16px]">
				Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information
				such as user passwords.
			</li>
			<li className="text-[16px]">
				Circumvent, disable, or otherwise interfere with security-related features of the Services, including features
				that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services
				and/or the Content contained therein.
			</li>
			<li className="text-[16px]">Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
			<li className="text-[16px]">
				Use any information obtained from the Services in order to harass, abuse, or harm another person.
			</li>
			<li className="text-[16px]">
				Make improper use of our support services or submit false reports of abuse or misconduct.
			</li>
			<li className="text-[16px]">
				Use the Services in a manner inconsistent with any applicable laws or regulations.
			</li>
			<li className="text-[16px]">Engage in unauthorized framing of or linking to the Services.</li>
			<li className="text-[16px]">
				Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including
				excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any
				party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes
				with the use, features, functions, operation, or maintenance of the Services.
			</li>
			<li className="text-[16px]">
				Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data
				mining, robots, or similar data gathering and extraction tools.
			</li>
			<li className="text-[16px]">Delete the copyright or other proprietary rights notice from any Content.</li>
			<li className="text-[16px]">
				Attempt to impersonate another user or person or use the username of another user.
			</li>
			<li className="text-[16px]">
				Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active
				information collection or transmission mechanism, including without limitation, clear graphics interchange
				formats ("gifs"), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as "spyware" or
				"passive collection mechanisms" or "pcms").
			</li>
			<li className="text-[16px]">
				Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the
				Services.
			</li>
			<li className="text-[16px]">
				Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the
				Services to you.
			</li>
			<li className="text-[16px]">
				Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any
				portion of the Services.
			</li>
			<li className="text-[16px]">
				Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.
			</li>
			<li className="text-[16px]">
				Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software
				comprising or in any way making up a part of the Services.
			</li>
			<li className="text-[16px]">
				Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or
				distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or
				offline reader that accesses the Services, or use or launch any unauthorized script or other software.
			</li>
			<li className="text-[16px]">Use a buying agent or purchasing agent to make purchases on the Services.</li>
			<li className="text-[16px]">
				Make any unauthorized use of the Services, including collecting usernames and/or email addresses of users by
				electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated
				means or under false pretenses.
			</li>
			<li className="text-[16px]">
				Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for
				any revenue-generating endeavor or commercial enterprise.
			</li>
			<li className="text-[16px]">Sell or otherwise transfer your profile.</li>
			<li className="text-[16px]">Use the Services to advertise or offer to sell goods and services.</li>
		</ul>

		<h2 className="text-xl font-bold mt-6">9. USER GENERATED CONTRIBUTIONS</h2>
		<div className="text-md mt-2">
			The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and
			other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform,
			publish, distribute, or broadcast content and materials to us or on the Services, including but not limited to
			text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other
			material (collectively, "Contributions"). Contributions may be viewable by other users of the Services and through
			third-party websites. As such, any Contributions you transmit may be treated as non-confidential and
			non-proprietary.
		</div>
		<div className="text-md mt-2">
			When you create or make available any Contributions, you thereby represent and warrant that:
		</div>
		<ul className="list-disc list-inside text-md mt-2">
			<li className="text-[16px]">
				The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or
				copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to
				the copyright, patent, trademark, trade secret, or moral rights of any third party.
			</li>
			<li className="text-[16px]">
				You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to
				use and to authorize us, the Services, and other users of the Services to use your Contributions in any manner
				contemplated by the Services and these Legal Terms.
			</li>
			<li className="text-[16px]">
				You have the written consent, release, and/or permission of each and every identifiable individual person in
				your Contributions to use the name or likeness of each and every such identifiable individual person to enable
				inclusion and use of your Contributions in any manner contemplated by the Services and these Legal Terms.
			</li>
			<li className="text-[16px]">Your Contributions are not false, inaccurate, or misleading.</li>
			<li className="text-[16px]">
				Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes,
				chain letters, spam, mass mailings, or other forms of solicitation.
			</li>
			<li className="text-[16px]">
				Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or
				otherwise objectionable (as determined by us).
			</li>
			<li className="text-[16px]">Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</li>
			<li className="text-[16px]">
				Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and
				to promote violence against a specific person or class of people.
			</li>
			<li className="text-[16px]">Your Contributions do not violate any applicable law, regulation, or rule.</li>
			<li className="text-[16px]">
				Your Contributions do not violate the privacy or publicity rights of any third party.
			</li>
			<li className="text-[16px]">
				Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to
				protect the health or well-being of minors.
			</li>
			<li className="text-[16px]">
				Your Contributions do not include any offensive comments that are connected to race, national origin, gender,
				sexual preference, or physical handicap.
			</li>
			<li className="text-[16px]">
				Your Contributions do not otherwise violate, or link to material that violates, any provision of these Legal
				Terms, or any applicable law or regulation.
			</li>
		</ul>
		<div className="text-md mt-2">
			Any use of the Services in violation of the foregoing violates these Legal Terms and may result in, among other
			things, termination or suspension of your rights to use the Services.
		</div>

		<h2 className="text-xl font-bold mt-6">10. CONTRIBUTION LICENSE</h2>
		<div className="text-md mt-2">
			By posting your Contributions to any part of the Services, you automatically grant, and you represent and warrant
			that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
			transferable, royalty-free, fully-paid, worldwide right, and license to host, use, copy, reproduce, disclose,
			sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat,
			translate, transmit, excerpt (in whole or in part), and distribute such Contributions (including, without
			limitation, your image and voice) for any purpose, commercial, advertising, or otherwise, and to prepare
			derivative works of, or incorporate into other works, such Contributions, and grant and authorize sublicenses of
			the foregoing. The use and distribution may occur in any media formats and through any media channels.
		</div>
		<div className="text-md mt-2">
			This license will apply to any form, media, or technology now known or hereafter developed, and includes our use
			of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade
			names, logos, and personal and commercial images you provide. You waive all moral rights in your Contributions,
			and you warrant that moral rights have not otherwise been asserted in your Contributions.
		</div>
		<div className="text-md mt-2">
			We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and
			any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable
			for any statements or representations in your Contributions provided by you in any area on the Services. You are
			solely responsible for your Contributions to the Services and you expressly agree to exonerate us from any and all
			responsibility and to refrain from any legal action against us regarding your Contributions.
		</div>
		<div className="text-md mt-2">
			We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any
			Contributions; (2) to re-categorize any Contributions to place them in more appropriate locations on the Services;
			and (3) to pre-screen or delete any Contributions at any time and for any reason, without notice. We have no
			obligation to monitor your Contributions.
		</div>

		<h2 className="text-xl font-bold mt-6">11. THIRD-PARTY WEBSITES AND CONTENT</h2>
		<div className="text-md mt-2">
			The Services may contain (or you may be sent via the Site) links to other websites ("Third-Party Websites") as
			well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications,
			software, and other content or items belonging to or originating from third parties ("Third-Party Content"). Such
			Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy,
			appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites accessed through
			the Services or any Third-Party Content posted on, available through, or installed from the Services.
		</div>
		<div className="text-md mt-2">
			If you decide to leave the Services and access the Third-Party Websites or to use or install any Third-Party
			Content, you do so at your own risk, and you should be aware these Legal Terms no longer govern. Any purchases you
			make through Third-Party Websites will be through other websites and from other companies, and we take no
			responsibility whatsoever in relation to such purchases which are exclusively between you and the applicable third
			party.
		</div>

		<h2 className="text-xl font-bold mt-6">12. ADVERTISERS</h2>
		<div className="text-md mt-2">
			We allow advertisers to display their advertisements and other information in certain areas of the Services, such
			as sidebar advertisements or banner advertisements. We simply provide the space to place such advertisements, and
			we have no other relationship with advertisers.
		</div>

		<h2 className="text-xl font-bold mt-6">13. SERVICES MANAGEMENT</h2>
		<div className="text-md mt-2">
			We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal Terms;
			(2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal
			Terms; (3) refuse, restrict access to, limit the availability of, or disable (to the extent technologically
			feasible) any of your Contributions; (4) remove from the Services or otherwise disable all files and content that
			are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Services in a
			manner designed to protect our rights and property and to facilitate the proper functioning of the Services.
		</div>

		<h2 className="text-xl font-bold mt-6">14. PRIVACY POLICY</h2>
		<div className="text-md mt-2">
			We care about data privacy and security. Please review our Privacy Policy. By using the Services, you agree to be
			bound by our Privacy Policy, which is incorporated into these Legal Terms.
		</div>

		<h2 className="text-xl font-bold mt-6">15. COPYRIGHT INFRINGEMENTS</h2>
		<div className="text-md mt-2">
			We respect the intellectual property rights of others. If you believe that any material available on or through
			the Services infringes upon any copyright you own or control, please notify us using the contact information
			provided below.
		</div>

		<h2 className="text-xl font-bold mt-6">16. TERM AND TERMINATION</h2>
		<div className="text-md mt-2">
			These Legal Terms shall remain in full force and effect while you use the Services. We reserve the right, in our
			sole discretion and without notice or liability, to deny access to and use of the Services to any person for any
			reason or for no reason.
		</div>

		<h2 className="text-xl font-bold mt-6">17. MODIFICATIONS AND INTERRUPTIONS</h2>
		<div className="text-md mt-2">
			We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at
			our sole discretion without notice. We will not be liable to you or any third party for any modification, price
			change, suspension, or discontinuance of the Services.
		</div>

		<h2 className="text-xl font-bold mt-6">18. GOVERNING LAW</h2>
		<div className="text-md mt-2">
			These Legal Terms and your use of the Services are governed by and construed in accordance with the laws of the
			State of Delaware.
		</div>

		<h2 className="text-xl font-bold mt-6">19. DISPUTE RESOLUTION</h2>
		<div className="text-md mt-2">
			To expedite resolution and control the cost of any dispute, controversy, or claim related to these Legal Terms,
			the Parties agree to first attempt to negotiate any Dispute informally for at least thirty (30) days before
			initiating arbitration.
		</div>
		<h3 className="text-lg font-semibold mt-4">Binding Arbitration</h3>
		<div className="text-md mt-2">
			If the Parties are unable to resolve a Dispute through informal negotiations, the Dispute will be finally and
			exclusively resolved by binding arbitration.
		</div>

		<h2 className="text-xl font-bold mt-6">20. CORRECTIONS</h2>
		<div className="text-md mt-2">
			There may be information on the Services that contains typographical errors, inaccuracies, or omissions. We
			reserve the right to correct any errors and update information without prior notice.
		</div>

		<h2 className="text-xl font-bold mt-6">21. DISCLAIMER</h2>
		<div className="text-md mt-2">
			THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT
			YOUR SOLE RISK. WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED.
		</div>

		<h2 className="text-xl font-bold mt-6">22. LIMITATIONS OF LIABILITY</h2>
		<div className="text-md mt-2">
			IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
			INDIRECT, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING FROM YOUR USE OF THE SERVICES.
		</div>

		<h2 className="text-xl font-bold mt-6">23. AFFILIATE PROGRAM</h2>
		<div className="text-md mt-2">
			We offer an affiliate program that allows affiliates to earn a commission of 20% on all earnings, including
			recurring payments, generated through their unique affiliate link.
		</div>

		<h2 className="text-xl font-bold mt-6">24. INDEMNIFICATION</h2>
		<div className="text-md mt-2">
			You agree to defend, indemnify, and hold us harmless from any loss, damage, liability, claim, or demand made by
			any third party due to or arising out of your use of the Services.
		</div>

		<h2 className="text-xl font-bold mt-6">25. USER DATA</h2>
		<div className="text-md mt-2">
			We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the
			Services. However, you are solely responsible for all data that you transmit.
		</div>

		<h2 className="text-xl font-bold mt-6">26. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h2>
		<div className="text-md mt-2">
			Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You
			consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other
			communications we provide to you electronically satisfy any legal requirement that such communication be in
			writing.
		</div>

		<h2 className="text-xl font-bold mt-6">27. CALIFORNIA USERS AND RESIDENTS</h2>
		<div className="text-md mt-2">
			If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the
			Division of Consumer Services of the California Department of Consumer Affairs.
		</div>

		<h2 className="text-xl font-bold mt-6">28. MISCELLANEOUS</h2>
		<div className="text-md mt-2">
			These Legal Terms constitute the entire agreement between you and us. If any provision of these Legal Terms is
			determined to be unlawful, void, or unenforceable, that provision is deemed severable and does not affect the
			validity and enforceability of the remaining provisions.
		</div>

		<h2 className="text-xl font-bold mt-6">29. REFUNDS</h2>
		<div className="text-md mt-2">
			Refunds will be provided when required by applicable law. Due to the costly nature of AI services, we are unable
			to issue refunds for any purchases made on the Services.
		</div>

		<h2 className="text-xl font-bold mt-6">30. CONTACT US</h2>
		<div className="text-md mt-2">
			In order to resolve a complaint regarding the Services or to receive further information, please contact us at:
		</div>
		<div className="text-md mt-2">
			Clarify AI
			<br />
			<a href="mailto:support@clarify.ai" className="text-blue-600">
				support@clarify.ai
			</a>
		</div>

		<h2 className="text-xl font-bold mt-6">31. ETHICS</h2>
		<div className="text-md mt-2">
			At Clarify AI, we firmly condemn the use of our tool for academic dishonesty or cheating. Our platform is designed
			to enhance AI content authentically, and we urge all users to utilize it responsibly and ethically.
		</div>
		<div className="text-md mt-2">
			We are dedicated to combating academic misconduct through our technology while delivering invaluable resources for
			marketing and creative professionals. We believe that ethical use of our platform is crucial for upholding trust
			and integrity in the industry.
		</div>
	</div>
)

export default TermsOfService
