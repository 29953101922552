import { atom } from 'jotai'

export const MAX_HUMANIZER_WORD_COUNT = 2500
export const MAX_DETECTOR_WORD_COUNT = 2500

export enum HumanizerIntensityLevel {
	LOW = 'LOW',
	MEDIUM = 'MEDIUM',
	HIGH = 'HIGH',
}

export enum LiteracyLevel {
	HIGH_SCHOOL = 'HIGH_SCHOOL',
	COLLEGE = 'COLLEGE',
	DOCTORATE = 'DOCTORATE',
}

export enum Purpose {
	GENERAL = 'GENERAL',
	ESSAY = 'ESSAY',
	ARTICLE = 'ARTICLE',
	CREATIVE = 'CREATIVE',
	TECHNICAL = 'TECHNICAL',
	LEGAL = 'LEGAL',
}

export const humanizerIntensityLevelDisplayNames = {
	[HumanizerIntensityLevel.LOW]: 'More Readable',
	[HumanizerIntensityLevel.MEDIUM]: 'Balanced',
	[HumanizerIntensityLevel.HIGH]: 'More Human',
}

export const literacyLevelDisplayNames = {
	[LiteracyLevel.HIGH_SCHOOL]: 'High School',
	[LiteracyLevel.COLLEGE]: 'College',
	[LiteracyLevel.DOCTORATE]: 'Doctorate',
}

export const purposeDisplayNames = {
	[Purpose.GENERAL]: 'General',
	[Purpose.ESSAY]: 'Essay',
	[Purpose.ARTICLE]: 'Article',
	[Purpose.CREATIVE]: 'Creative',
	[Purpose.TECHNICAL]: 'Technical',
	[Purpose.LEGAL]: 'Legal',
}

export type DetectionResponse = {
	openai: number
	gptzero: number
	writer: number
	crossplag: number
	copyleaks: number
	sapling: number
	contentatscale: number
	zerogpt: number
	ai: number
}

type EditorAtomSchema = {
	humanizerIntensityLevel: HumanizerIntensityLevel
	purpose: Purpose
	literacyLevel: LiteracyLevel
	textInputValue: string
	isLoadingHumanizeText: boolean
	aiDetectionScoreLoading: boolean
	aiDetectionScore?: DetectionResponse
	aiDetectionScoreText?: string
	previousHumanization?: {
		text: string
		intensityLevel: HumanizerIntensityLevel
	}
	showDetectionBox: boolean
	currentDocumentId?: string
	humanizedText?: string
}

export const editorDefaultValues: EditorAtomSchema = {
	humanizerIntensityLevel: HumanizerIntensityLevel.MEDIUM,
	purpose: Purpose.GENERAL,
	literacyLevel: LiteracyLevel.COLLEGE,
	textInputValue: '',
	isLoadingHumanizeText: false,
	aiDetectionScoreLoading: false,
	aiDetectionScoreText: undefined,
	aiDetectionScore: undefined,
	previousHumanization: undefined,
	showDetectionBox: true,
	currentDocumentId: undefined,
	humanizedText: undefined,
}

export const editorAtom = atom<EditorAtomSchema>(editorDefaultValues)
