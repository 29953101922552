import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../_Modal.scss'
import './_Login.scss'
import * as Sentry from '@sentry/react'
import { selectUser, setUser } from '../../../redux/systemSlice'
import useCheckout from '../../../hooks/checkout'
import { useAuth } from '../../../contexts/Auth'
import Button from '../../Buttons'
import Modal from '../Modal'
import { NULL_USER } from '../../../constants'
import SignupContent from './Signup'
import LoginContent from './Login'
import { atom, useAtom } from 'jotai'
import useIsPremium from '../../../hooks/useIsPremium'
import { FaRegCheckCircle } from 'react-icons/fa'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../../hooks/featureFlags'

type ModalAtomSchema = {
	goToCheckout: boolean
}

const defaultValues: ModalAtomSchema = {
	goToCheckout: false,
}

export const modalAtom = atom<ModalAtomSchema>(defaultValues)

function LoginModal({
	open,
	setModalOpen,
	premiumPath,
	loginVariant = 'login',
	annualPlan,
	redirectUrl,
}: {
	open: boolean
	setModalOpen: (open: boolean) => void
	premiumPath?: boolean
	loginVariant?: 'login' | 'signup'
	annualPlan: boolean
	redirectUrl?: string
}) {
	const signupPanelFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SIGN_UP_PANEL)
	const user = useSelector(selectUser)
	const [variant, setVariant] = useState<'login' | 'signup'>(premiumPath ? 'signup' : loginVariant)
	const { currentUser } = useAuth() as any
	const [forgotPassword, setForgotPassword] = useState(false)
	const [loginModalState, setLoginModalState] = useAtom(modalAtom)
	const dispatch = useDispatch()
	const { checkout } = useCheckout()
	const isPremium = useIsPremium()

	const goToCheckout = loginModalState.goToCheckout
	const setGoToCheckout = (goToCheckout: boolean) => setLoginModalState({ goToCheckout })

	useEffect(() => {
		if (currentUser && goToCheckout && user && user.uid && !isPremium) {
			checkout(annualPlan)
		}
	}, [currentUser, goToCheckout, user, annualPlan])

	useEffect(() => {
		if (premiumPath) {
			setVariant('signup')
		}
	}, [premiumPath])

	useEffect(() => {
		if (loginVariant) {
			setVariant(loginVariant)
		}
	}, [loginVariant])

	let modalContent = <></>

	if (variant === 'login') {
		modalContent = (
			<LoginContent
				setModalOpen={setModalOpen}
				premiumPath={!!premiumPath}
				setGoToCheckout={setGoToCheckout}
				setVariant={setVariant}
			/>
		)
	} else if (forgotPassword) {
		modalContent = (
			<>
				<div>
					<h5 className=" header-md">Forgot Password</h5>
					<p>Check your email for further instructions</p>
				</div>
				<Button
					type="tertiary"
					onClick={() => {
						setForgotPassword(false)
					}}
				>
					<span>Login</span>
				</Button>
			</>
		)
	} else {
		modalContent = (
			<SignupContent
				setVariant={setVariant}
				setModalOpen={setModalOpen}
				premiumPath={premiumPath}
				setGoToCheckout={setGoToCheckout}
				redirectUrl={redirectUrl}
			/>
		)
	}

	if (signupPanelFF !== 'test') {
		return (
			<>
				<Modal
					portalClassName="login wide"
					open={open}
					closeModal={() => {
						setModalOpen(false)
						dispatch(setUser(NULL_USER))
						Sentry.setUser(null)
					}}
				>
					<div className="flex gap-2">
						<div className="md:w-[450px] w-full md:py-10 md:px-8 px-3">{modalContent}</div>
						<div className="md:flex hidden bg-secondary grow items-center justify-center py-8">
							<div className="w-5/6 flex flex-col gap-10">
								<div className="flex flex-col gap-2">
									<h5 className="text-3xl text-center text-white font-semibold">Sign up for FREE!</h5>
									<div className="text-gray-200 mb-0 text-center text-xl">
										Get{' '}
										<span className="font-semibold border-b border-primary-100 pb-0.5 inline-block">
											300 free words
										</span>{' '}
										when you sign up.
									</div>
								</div>
								<div className="flex flex-col gap-4">
									{[
										'100% Undetectable by Turnitin',
										'Unlimited AI-Detection',
										'Unlimited Content Generations',
										'Satisfaction Guaranteed',
									].map((feature, index) => (
										<div key={index} className="flex items-center gap-2">
											<FaRegCheckCircle className="text-green-300" size={22} />
											<div className="text-white text-lg">{feature}</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</>
		)
	} else {
		return (
			<>
				<Modal
					portalClassName="login"
					open={open}
					closeModal={() => {
						setModalOpen(false)
						dispatch(setUser(NULL_USER))
						Sentry.setUser(null)
					}}
				>
					{modalContent}
				</Modal>
			</>
		)
	}
}

export default LoginModal
