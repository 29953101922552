'use client'

import { useEffect, useState } from 'react'
import AutoScroll from 'embla-carousel-auto-scroll'
import { Carousel, CarouselContent, CarouselItem } from './ui/carousel'
import Berkely from '../assets/images/logos/berkeley.webp'
import Cambridge from '../assets/images/logos/cambridge.webp'
import Mit from '../assets/images/logos/mit.webp'
import Oxford from '../assets/images/logos/oxford.webp'
import Penn from '../assets/images/logos/penn.webp'
import Stanford from '../assets/images/logos/stanford.webp'
import Yale from '../assets/images/logos/yale.webp'

const LogoCarousel = () => {
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	if (!mounted) {
		return null
	}

	const logos = [
		{ name: 'berkeley', width: 256, height: 144, src: Berkely },
		{ name: 'cambridge', width: 256, height: 170, src: Cambridge },
		{ name: 'mit', width: 256, height: 170, src: Mit },
		{ name: 'oxford', width: 256, height: 108, src: Oxford },
		{ name: 'penn', width: 256, height: 170, src: Penn },
		{ name: 'stanford', width: 256, height: 112, src: Stanford },
		{ name: 'yale', width: 256, height: 103, src: Yale },
	]

	return (
		<Carousel
			opts={{
				align: 'start',
				loop: true,
			}}
			plugins={[AutoScroll({ startDelay: 0, speed: 1, stopOnInteraction: false })]}
			className="[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
		>
			<CarouselContent>
				{logos.map((logo) => (
					<CarouselItem key={logo.name} className="lg::basis-1/5 basis-1/2 sm:basis-1/3 md:basis-1/4">
						<img
							src={logo.src}
							alt={`${logo.name} logo`}
							width={logo.width}
							height={logo.height}
							className="h-24 w-56 object-contain"
						/>
					</CarouselItem>
				))}
			</CarouselContent>
		</Carousel>
	)
}

export default LogoCarousel
