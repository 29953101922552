import { useState } from 'react'
import Button from '../../Buttons'
import { useAuth } from '../../../contexts/Auth'
import { isValidEmail } from '../../../helpers/utility'

const ForgotPassword = ({ setForgotPassword }: { setForgotPassword: (forgotPassword: boolean) => void }) => {
	const [emailInput, setEmailInput] = useState<string | undefined>('')
	const [emailSent, setEmailSent] = useState(false)
	const { resetPassword } = useAuth() as any

	const handleForgotPassword = () => {
		resetPassword(emailInput)
		setEmailSent(true)
	}

	return (
		<div className="flex flex-col gap-4">
			<div>
				<div className="flex flex-col gap-2">
					<h5 className="text-2xl text-center">{emailSent ? 'Password Reset Sent!' : 'Forgot Password?'}</h5>
					<p className="text-[18px] text-gray-600 text-center mb-8">
						{emailSent
							? 'Check your email for further instructions'
							: 'Please enter the email used to create your Clarify account'}
					</p>
				</div>
				{!emailSent && (
					<>
						<input
							placeholder="Enter your email address"
							onChange={(e) => {
								setEmailInput(e.target.value)
							}}
							value={emailInput}
							type="email"
							id="enter-email-address"
							className="border border-gray-500 focus:border-gray-700 w-full p-1"
							autoFocus
						/>
						<div className="text-gray-700 text-sm mt-2">We’ll send you an email with a reset link.</div>
					</>
				)}
			</div>
			{!emailSent && (
				<Button
					type="tertiary"
					onClick={() => {
						handleForgotPassword()
					}}
					emphasize
					className="w-full py-2 font-semibold"
					disabled={!emailInput || !isValidEmail(emailInput)}
				>
					<span>Request Password Reset</span>
				</Button>
			)}
			<div className="text-gray-700 cursor-pointer text-center" onClick={() => setForgotPassword(false)}>
				Back to login
			</div>
		</div>
	)
}

export default ForgotPassword
