import React from 'react'

const PrivacyPolicy = () => (
	<div className="max-w-5xl mx-auto my-24">
		<h1 className="text-2xl font-bold">Privacy Policy</h1>
		<div className="text-md">Last Updated: October 22, 2024</div>

		<div className="text-md mt-4">
			This privacy notice for Clarify AI. (doing business as Clarify AI) ("Clarify.ai," "we," "us," or "our"), describes
			how and why we might collect, store, use, and/or share ("process") your information when you use our services
			("Services"), such as when you:
		</div>
		<ul className="list-disc list-inside text-md mt-2">
			<li className="text-[16px]">
				Visit our website at{' '}
				<a href="https://clarify.ai" className="text-blue-600">
					https://clarify.ai
				</a>
				, or any website of ours that links to this privacy notice
			</li>
			<li className="text-[16px]">Engage with us in other related ways, including any sales, marketing, or events</li>
		</ul>
		<div className="text-md mt-4">
			Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If
			you do not agree with our policies and practices, please do not use our Services. If you still have any questions
			or concerns, please contact us at{' '}
			<a href="mailto:support@clarify.ai" className="text-blue-600">
				support@clarify.ai
			</a>
			.
		</div>

		<h2 className="text-xl font-bold mt-6">1. WHAT INFORMATION DO WE COLLECT?</h2>
		<h3 className="text-lg font-semibold mt-4">Personal information you disclose to us</h3>
		<div className="text-md mt-2">In Short: We collect personal information that you provide to us.</div>
		<div className="text-md mt-2">
			We collect personal information that you voluntarily provide to us when you register on the Services, express an
			interest in obtaining information about us or our products and Services, when you participate in activities on the
			Services, or otherwise when you contact us.
		</div>
		<h3 className="text-lg font-semibold mt-4">Personal Information Provided by You</h3>
		<div className="text-md mt-2">
			The personal information that we collect depends on the context of your interactions with us and the Services, the
			choices you make, and the products and features you use. The personal information we collect may include the
			following:
		</div>
		<ul className="list-disc list-inside text-md mt-2">
			<li className="text-[16px]">names</li>
			<li className="text-[16px]">email addresses</li>
			<li className="text-[16px]">usernames</li>
			<li className="text-[16px]">passwords</li>
			<li className="text-[16px]">contact preferences</li>
			<li className="text-[16px]">contact or authentication data</li>
		</ul>
		<div className="text-md mt-2">Sensitive Information. We do not process sensitive information.</div>

		<h3 className="text-lg font-semibold mt-4">Payment Data</h3>
		<div className="text-md mt-2">
			We may collect data necessary to process your payment if you make purchases, such as your payment instrument
			number, and the security code associated with your payment instrument. All payment data is stored by Stripe, Inc..
			You may find their privacy notice link(s) here:{' '}
			<a href="https://stripe.com/privacy" className="text-blue-600">
				https://stripe.com/privacy
			</a>
			.
		</div>
		<div className="text-md mt-2">
			All personal information that you provide to us must be true, complete, and accurate, and you must notify us of
			any changes to such personal information.
		</div>

		<h3 className="text-lg font-semibold mt-4">Information automatically collected</h3>
		<div className="text-md mt-2">
			In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics
			— is collected automatically when you visit our Services.
		</div>
		<div className="text-md mt-2">
			We automatically collect certain information when you visit, use, or navigate the Services. This information does
			not reveal your specific identity (like your name or contact information) but may include device and usage
			information, such as your IP address, browser and device characteristics, operating system, language preferences,
			referring URLs, device name, country, location, information about how and when you use our Services, and other
			technical information. This information is primarily needed to maintain the security and operation of our
			Services, and for our internal analytics and reporting purposes.
		</div>
		<div className="text-md mt-2">
			Like many businesses, we also collect information through cookies and similar technologies.
		</div>
		<h3 className="text-lg font-semibold mt-4">The information we collect includes:</h3>
		<ul className="list-disc list-inside text-md mt-2">
			<li className="text-[16px]">
				<strong>Log and Usage Data:</strong> Log and usage data is service-related, diagnostic, usage, and performance
				information our servers automatically collect when you access or use our Services and which we record in log
				files.
			</li>
			<li className="text-[16px]">
				<strong>Device Data:</strong> We collect device data such as information about your computer, phone, tablet, or
				other device you use to access the Services.
			</li>
			<li className="text-[16px]">
				<strong>Location Data:</strong> We collect location data such as information about your device's location, which
				can be either precise or imprecise.
			</li>
		</ul>

		<h2 className="text-xl font-bold mt-6">2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
		<div className="text-md mt-2">
			In Short: We process your information to provide, improve, and administer our Services, communicate with you, for
			security and fraud prevention, and to comply with law. We may also process your information for other purposes
			with your consent.
		</div>
		<div className="text-md mt-2">
			We process your personal information for a variety of reasons, depending on how you interact with our Services,
			including:
		</div>
		<ul className="list-disc list-inside text-md mt-2">
			<li className="text-[16px]">
				To facilitate account creation and authentication and otherwise manage user accounts.
			</li>
			<li className="text-[16px]">To deliver and facilitate delivery of services to the user.</li>
			<li className="text-[16px]">To respond to user inquiries/offer support to users.</li>
			<li className="text-[16px]">To fulfill and manage your orders.</li>
			<li className="text-[16px]">To enable user-to-user communications.</li>
			<li className="text-[16px]">To request feedback.</li>
			<li className="text-[16px]">To send you marketing and promotional communications.</li>
			<li className="text-[16px]">To deliver targeted advertising to you.</li>
			<li className="text-[16px]">To protect our Services.</li>
			<li className="text-[16px]">To identify usage trends.</li>
			<li className="text-[16px]">To determine the effectiveness of our marketing and promotional campaigns.</li>
			<li className="text-[16px]">To save or protect an individual's vital interest.</li>
		</ul>

		<h2 className="text-xl font-bold mt-6">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
		<div className="text-md mt-2">
			In Short: We only process your personal information when we believe it is necessary and we have a valid legal
			reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide
			you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our
			legitimate business interests.
		</div>
		<div className="text-md mt-2">If you are located in the EU or UK, this section applies to you.</div>
		<div className="text-md mt-2">
			The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on
			in order to process your personal information. As such, we may rely on the following legal bases to process your
			personal information:
		</div>
		<ul className="list-disc list-inside text-md mt-2">
			<li className="text-[16px]">
				<strong>Consent:</strong> We may process your information if you have given us permission (i.e., consent) to use
				your personal information for a specific purpose. You can withdraw your consent at any time.
			</li>
			<li className="text-[16px]">
				<strong>Performance of a Contract:</strong> We may process your personal information when we believe it is
				necessary to fulfill our contractual obligations to you, including providing our Services or at your request
				prior to entering into a contract with you.
			</li>
			<li className="text-[16px]">
				<strong>Legitimate Interests:</strong> We may process your information when we believe it is reasonably
				necessary to achieve our legitimate business interests and those interests do not outweigh your interests and
				fundamental rights and freedoms.
			</li>
			<li className="text-[16px]">
				<strong>Legal Obligations:</strong> We may process your information where we believe it is necessary for
				compliance with our legal obligations.
			</li>
			<li className="text-[16px]">
				<strong>Vital Interests:</strong> We may process your information where we believe it is necessary to protect
				your vital interests or the vital interests of a third party.
			</li>
		</ul>

		<h2 className="text-xl font-bold mt-6">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
		<div className="text-md mt-2">
			In Short: We may share information in specific situations described in this section and/or with the following
			third parties.
		</div>
		<div className="text-md mt-2">We may need to share your personal information in the following situations:</div>
		<ul className="list-disc list-inside text-md mt-2">
			<li className="text-[16px]">
				<strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during
				negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our
				business to another company.
			</li>
		</ul>

		<h2 className="text-xl font-bold mt-6">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
		<div className="text-md mt-2">
			In Short: We may use cookies and other tracking technologies to collect and store your information.
		</div>
		<div className="text-md mt-2">
			We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information.
			Specific information about how we use such technologies and how you can refuse certain cookies is set out in our
			Cookie Notice.
		</div>

		<h2 className="text-xl font-bold mt-6">6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h2>
		<div className="text-md mt-2">
			In Short: We may transfer, store, and process your information in countries other than your own.
		</div>
		<div className="text-md mt-2">
			Our servers are located in the United States. If you are accessing our Services from outside, please be aware that
			your information may be transferred to, stored, and processed by us in our facilities and by those third parties
			with whom we may share your personal information.
		</div>
		<div className="text-md mt-2">
			If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries may not
			necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we
			will take all necessary measures to protect your personal information in accordance with this privacy notice and
			applicable law.
		</div>

		<h2 className="text-xl font-bold mt-6">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
		<div className="text-md mt-2">
			In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy
			notice unless otherwise required by law.
		</div>
		<div className="text-md mt-2">
			We will only keep your personal information for as long as it is necessary for the purposes set out in this
			privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or
			other legal requirements).
		</div>
		<div className="text-md mt-2">
			When we have no ongoing legitimate business need to process your personal information, we will either delete or
			anonymize such information, or, if this is not possible (for example, because your personal information has been
			stored in backup archives), then we will securely store your personal information and isolate it from any further
			processing until deletion is possible.
		</div>

		<h2 className="text-xl font-bold mt-6">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
		<div className="text-md mt-2">
			In Short: We aim to protect your personal information through a system of organizational and technical security
			measures.
		</div>
		<div className="text-md mt-2">
			We have implemented appropriate and reasonable technical and organizational security measures designed to protect
			the security of any personal information we process. However, despite our safeguards and efforts to secure your
			information, no electronic transmission over the Internet or information storage technology can be guaranteed to
			be 100% secure.
		</div>
		<div className="text-md mt-2">
			Although we will do our best to protect your personal information, transmission of personal information to and
			from our Services is at your own risk. You should only access the Services within a secure environment.
		</div>

		<h2 className="text-xl font-bold mt-6">9. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
		<div className="text-md mt-2">
			In Short: In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have
			rights that allow you greater access to and control over your personal information.
		</div>
		<div className="text-md mt-2">
			You may review, change, or terminate your account at any time. In some regions, you have certain rights under
			applicable data protection laws. These may include the right (i) to request access and obtain a copy of your
			personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal
			information; and (iv) if applicable, to data portability.
		</div>

		<h2 className="text-xl font-bold mt-6">10. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
		<div className="text-md mt-2">
			Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature
			or setting you can activate to signal your privacy preference not to have data about your online browsing
			activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing
			DNT signals has been finalized.
		</div>
		<div className="text-md mt-2">
			As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates
			your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the
			future, we will inform you about that practice in a revised version of this privacy notice.
		</div>

		<h2 className="text-xl font-bold mt-6">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
		<div className="text-md mt-2">
			In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your
			personal information.
		</div>
		<div className="text-md mt-2">
			California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are
			California residents to request and obtain from us, once a year and free of charge, information about categories
			of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and
			addresses of all third parties with which we shared personal information in the immediately preceding calendar
			year.
		</div>

		<h2 className="text-xl font-bold mt-6">12. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
		<div className="text-md mt-2">
			In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
		</div>
		<div className="text-md mt-2">
			We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised"
			date and the updated version will be effective as soon as it is accessible.
		</div>

		<h2 className="text-xl font-bold mt-6">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
		<div className="text-md mt-2">
			If you have questions or comments about this notice, you may email us at{' '}
			<a href="mailto:support@clarify.ai" className="text-blue-600">
				support@clarify.ai
			</a>
			.
		</div>

		<h2 className="text-xl font-bold mt-6">14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
		<div className="text-md mt-2">
			Based on the applicable laws of your country, you may have the right to request access to the personal information
			we collect from you, change that information, or delete it. To request to review, update, or delete your personal
			information, please fill out and submit a data subject access request.
		</div>
	</div>
)

export default PrivacyPolicy
