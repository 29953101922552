import { initializeApp, getApps } from 'firebase/app'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'

getApps().length === 0 &&
	initializeApp({
		apiKey: 'AIzaSyAemUCRuzw2DW6cCEzgvHJWplLoo7bgr7w',
		authDomain: 'clarify-ai-9de8d.firebaseapp.com',
		projectId: 'clarify-ai-9de8d',
		storageBucket: 'clarify-ai-9de8d.appspot.com',
		messagingSenderId: '440896808403',
		appId: '1:440896808403:web:faaded9ad8ecc76453bb26',
		measurementId: 'G-5J993900Z6',
	})

export const auth = getAuth()

// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider()

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
	prompt: 'select_account ',
})
export const signInWithGooglePopup = () => signInWithPopup(auth, provider)
