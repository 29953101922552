import { useDispatch, useSelector } from 'react-redux'
import {
	selectCategoryScoresLoading,
	selectGraderCategory,
	selectNumAcceptedSuggestions,
	selectUser,
	setCategoryScoresLoading,
	setGraderCategory,
} from '../../redux/systemSlice'
import ScoreBar from './Scorebar'
import { useEffect, useMemo, useState } from 'react'
import { GraderRubric } from '../Navbar/Grader'
import { usePostHog } from 'posthog-js/react'
import { useAuth } from '../../contexts/Auth'
import { htmlToString } from '../../helpers/utility'
import { selectDocumentState, setContent } from '../../redux/docSlice'
import { useDocId } from '../../hooks/docID'
import { DocumentState } from '../../redux/types'
import * as Sentry from '@sentry/react'
import RubricModal from '../Modals/Rubric'

const categories = [
	{ name: 'Grammar', key: 'grammar' },
	{ name: 'Content', key: 'content' },
	{ name: 'Tone & Style', key: 'tone' },
]

type CategoryScores = {
	grammar: number
	content: number
	tone: number
}

const CategorySelector = () => {
	const [modalOpen, setModalOpen] = useState(false)
	const [gradeResult, setGradeResult] = useState<GraderRubric | null>(null)
	const [categoryScores, setCategoryScores] = useState<CategoryScores | null>(null)
	const [scoreLoading, setScoreLoading] = useState(false)
	const selectedGraderCategory = useSelector(selectGraderCategory)
	const numAcceptedSuggestions = useSelector(selectNumAcceptedSuggestions)
	const docID = useDocId()
	const documentState: DocumentState | undefined = useSelector((state) => selectDocumentState(state, docID))
	const user = useSelector(selectUser)
	const categoryScoresLoading = useSelector(selectCategoryScoresLoading)
	const { currentUser } = useAuth() as any
	const dispatch = useDispatch()
	const posthog = usePostHog()

	const handleRubricGrade = async () => {
		posthog.capture('grade-essay')
		setScoreLoading(true)
		setModalOpen(true)

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				topic: htmlToString(documentState?.title ?? ''),
				essay: htmlToString(documentState?.content ?? ''),
				userId: user.id,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/grade/', requestOptions)
			.then((res) => res.json())
			.then((response: GraderRubric) => {
				setGradeResult(response)
				setScoreLoading(false)
			})
			.catch((err) => {
				Sentry.captureException(err)
			})
	}

	const handleCategoryScores = async () => {
		dispatch(setCategoryScoresLoading(true))

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				topic: htmlToString(documentState?.title ?? ''),
				essay: htmlToString(documentState?.content ?? ''),
				userId: user.id,
				abridged: true,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/grade/', requestOptions)
			.then((res) => res.json())
			.then((response: CategoryScores) => {
				setCategoryScores(response)
			})
			.catch((err) => {
				Sentry.captureException(err)
			})
	}

	const getSuggestions = async () => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				topic: htmlToString(documentState?.title ?? ''),
				essay: htmlToString(documentState?.content ?? ''),
				userId: user.id,
				abridged: true,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/edit/', requestOptions)
			.then((res) => res.json())
			.then((response: { essay?: string }) => {
				if (!!response.essay) {
					dispatch(setContent({ docID: docID, content: response.essay }))
				}
				dispatch(setCategoryScoresLoading(false))
			})
			.catch((err) => {
				Sentry.captureException(err)
			})
	}

	useEffect(() => {
		if (documentState && documentState.content !== '<p></p>' && categoryScores === null && !categoryScoresLoading) {
			handleCategoryScores()
			getSuggestions()
		}
	}, [documentState?.content, categoryScores, categoryScoresLoading])

	const numSuggestionsInitial = useMemo(() => {
		if (!documentState?.content) {
			return {
				grammar: 0,
				content: 0,
				tone: 0,
			}
		}
		return {
			grammar: documentState.content.match(/data-focus="grammar"/g)?.length ?? 0,
			content: documentState.content.match(/data-focus="content"/g)?.length ?? 0,
			tone: documentState.content.match(/data-focus="tone"/g)?.length ?? 0,
		}
	}, [categoryScoresLoading])

	const numSuggestionsCurrent = useMemo(() => {
		if (!documentState?.content) {
			return {
				grammar: 0,
				content: 0,
				tone: 0,
			}
		}
		return {
			grammar: documentState.content.match(/data-focus="grammar"/g)?.length ?? 0,
			content: documentState.content.match(/data-focus="content"/g)?.length ?? 0,
			tone: documentState.content.match(/data-focus="tone"/g)?.length ?? 0,
		}
	}, [documentState?.content])

	const overallScore = useMemo(() => {
		if (
			numSuggestionsCurrent.grammar === 0 &&
			numSuggestionsCurrent.content === 0 &&
			numSuggestionsCurrent.tone === 0
		) {
			return 100
		}

		const baseScore = categoryScores
			? Math.floor((categoryScores?.grammar! + categoryScores?.content! + categoryScores?.tone!) / 3)
			: 0

		const suggestionScore = Math.floor(
			((100 - baseScore) /
				(numSuggestionsInitial.grammar + numSuggestionsInitial.content + numSuggestionsInitial.tone)) *
				numAcceptedSuggestions
		)
		return baseScore + suggestionScore
	}, [categoryScores, numSuggestionsInitial, numAcceptedSuggestions, numSuggestionsCurrent])

	return (
		<>
			<RubricModal
				isOpen={modalOpen}
				onClose={() => setModalOpen(false)}
				loading={scoreLoading}
				rubric={gradeResult}
				overallScore={overallScore}
			/>
			<div className="w-full bg-gray-50 h-20 mb-8 shadow-lg rounded sticky top-14 border border-gray-200 z-20">
				{categoryScoresLoading || !categoryScores ? (
					<div className="flex justify-center items-center h-full">
						<div className="text-lg font-semibold">Loading...</div>
						<div className="oscillating-bar absolute bottom-0 h-1 w-full" />
					</div>
				) : (
					<div className="grid grid-cols-5 h-full px-5">
						<div className="flex flex-col pt-2 gap-1 border-r border-gray-300">
							<div className="font-semibold text-sm">Overall score:</div>
							<div className="flex gap-0.5 items-baseline">
								<div className="text-3xl font-semibold">{overallScore}</div>
								<div className="text-xl font-semibold">%</div>
							</div>
						</div>
						{categories.map((category) => {
							// @ts-ignore
							const numCategorySuggestions = numSuggestionsCurrent[category.key]
							// @ts-ignore
							const score = numCategorySuggestions === 0 ? 100 : categoryScores[category.key]
							return (
								<div
									className={`cursor-pointer flex items-center justify-center flex-col px-2 w-full gap-1 border-r border-gray-300 transition-all duration-300 ${
										category.key === selectedGraderCategory ? 'shadow bg-white' : 'opacity-55'
									}`}
									onClick={() => dispatch(setGraderCategory(category.key))}
								>
									<div className="text-sm font-semibold">{category.name}</div>
									<div className="flex items-center w-full gap-2">
										<ScoreBar score={score / 10} fullWidth />
										<div className="text-xs">{score}%</div>
									</div>
									{numCategorySuggestions > 0 && (
										<div
											className={`text-sm text-gray-700 hover:text-gray-900 ${
												category.key === selectedGraderCategory ? 'block' : 'hidden'
											}`}
										>
											Accept {numCategorySuggestions} edits
										</div>
									)}
								</div>
							)
						})}
						<div className="flex flex-col justify-center">
							<div
								className="font-semibold text-right text-gray-800 text-sm cursor-pointer"
								onClick={handleRubricGrade}
							>
								View full analysis
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	)
}

export default CategorySelector
