import { useMemo } from 'react'

const TitleQuality = ({ topic }: { topic: string }) => {
	const wordCount = useMemo(() => {
		return topic.split(' ').filter((word) => word.length > 0).length
	}, [topic])

	if (wordCount === 0) {
		return <div className="h-1 border-b border-gray-900 " />
	}

	return (
		<div className="h-1 border border-gray-300 rounded">
			<div
				className={`h-full rounded border-r transition-all ${
					wordCount < 10 ? (wordCount < 5 ? 'bg-danger-default' : 'bg-warning-default') : 'bg-success-default'
				}`}
				style={{ width: `${((wordCount > 10 ? 10 : wordCount) / 10) * 100}%` }}
			/>
		</div>
	)
}

export default TitleQuality
