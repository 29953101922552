import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { X } from 'lucide-react'
import { cn } from '../../lib/utils'

const Modal = ({
	children,
	open,
	setOpen,
	className,
}: {
	children: React.ReactNode
	open: boolean
	setOpen: (open: boolean) => void
	className?: string
}) => (
	<Dialog.Root open={open} onOpenChange={setOpen}>
		<Dialog.Portal>
			<Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-30 bg-black opacity-30" />
			<Dialog.Content
				className={cn(
					'data-[state=open]:animate-contentShow fixed left-1/2 top-1/2 z-40 max-h-[85vh] w-[90vw] max-w-[450px] -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none',
					className
				)}
			>
				{children}
				<Dialog.Close asChild>
					<button
						className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-2.5 top-2.5 inline-flex size-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
						aria-label="Close"
					>
						<X />
					</button>
				</Dialog.Close>
			</Dialog.Content>
		</Dialog.Portal>
	</Dialog.Root>
)

export default Modal
