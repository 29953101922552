import { usePostHog } from 'posthog-js/react'
import Modal from './Modal'
import './_Subscriptions.scss'
import { useEffect } from 'react'
import PricingTable from '../../components-landing/pricing-table'
import { CreditCard } from 'lucide-react'

function Subscriptions({
	open,
	closeModal,
	noMoreCredits,
}: {
	open: boolean
	closeModal: () => void
	noMoreCredits?: boolean
}) {
	const posthog = usePostHog()

	useEffect(() => {
		posthog.capture('upgrade-modal-opened')
	}, [open])

	return (
		<Modal
			open={open}
			closeModal={() => {
				closeModal()
				posthog.capture('upgrade-modal-closed')
			}}
			portalClassName="subscription"
		>
			<div className="flex flex-col gap-4 items-center">
				<div className="h-9 w-9 flex justify-center items-center rounded-full bg-primary-200 text-primary-600 text-xl font-semibold">
					<CreditCard />
				</div>
				<div className="md:text-3xl text-2xl font-semibold text-center">
					{noMoreCredits && "You're out of words!"} 🚀 Upgrade now to keep writing.
				</div>
				<div className="text-center text-gray-700 max-w-lg mx-auto text-lg">
					🎉 Unlock more with an upgraded plan—experience our tools with no long-term commitment. Cancel anytime.
				</div>
				<div>{<PricingTable />}</div>
			</div>
		</Modal>
	)
}

export default Subscriptions
