import { useDispatch, useSelector } from 'react-redux'
import { selectUser, setUser } from '../redux/systemSlice'
import useIsPremium from './useIsPremium'
import { useEffect, useState } from 'react'

const useTestPayment = ({ onSuccess }: { onSuccess?: () => void }) => {
	const [testPaymentModalOpen, setTestPaymentModalOpen] = useState(false)
	const [checkingPayment, setCheckingPayment] = useState(false)
	const user = useSelector(selectUser)
	const dispatch = useDispatch()
	const isPremium = useIsPremium()

	const checkSuccessfulPayment = () => {
		const intervalId = setInterval(() => {
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					user_id: user.id,
				}),
			}

			fetch(process.env.REACT_APP_API_ROOT + '/payments/check-small-charge-status/', requestOptions)
				.then((res) => res.json())
				.then((response) => {
					console.log(response)
					if (response.paymentStatus === 'failed') {
						dispatch(setUser({ ...user, testPaymentIntentStatus: 'FAILED' }))
						setCheckingPayment(false)
						clearInterval(intervalId) // Stop polling on failure
					} else if (response.paymentStatus === 'succeeded') {
						onSuccess?.()
						dispatch(setUser({ ...user, testPaymentIntentStatus: 'SUCCEEDED' }))
						setCheckingPayment(false)
						setTestPaymentModalOpen(false)
						clearInterval(intervalId) // Stop polling on success
					}
				})
		}, 2000)
	}

	const createTestPayment = () => {
		setCheckingPayment(true)
		dispatch(setUser({ ...user, testPaymentIntentStatus: 'PENDING' }))

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				userId: user.id,
			}),
		}

		fetch(process.env.REACT_APP_API_ROOT + '/payments/create-small-charge/', requestOptions)
			.then((res) => res.json())
			.then((response: { paymentIntentId: string } | { error: string; type: string }) => {
				if ('error' in response) {
					if (response.type === 'card_error') {
						dispatch(setUser({ ...user, testPaymentIntentStatus: 'FAILED' }))
					}
				} else {
					dispatch(setUser({ ...user, testPaymentIntentId: response.paymentIntentId }))
					checkSuccessfulPayment()
				}
			})
	}

	useEffect(() => {
		const openModal = user.isOnFreeTrial && user.testPaymentIntentStatus !== 'SUCCEEDED'
		if (user.isOnFreeTrial && !user.testPaymentIntentId && !user.testPaymentIntentStatus && !checkingPayment) {
			createTestPayment()
		}
		if (user.isOnFreeTrial && user.testPaymentIntentStatus === 'PENDING' && !checkingPayment) {
			checkSuccessfulPayment()
		}
		setTestPaymentModalOpen(openModal)
	}, [user.isOnFreeTrial, user.testPaymentIntentId, user.testPaymentIntentStatus, checkingPayment, isPremium])

	return { createTestPayment, testPaymentModalOpen }
}

export default useTestPayment
