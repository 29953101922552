import { Bot, CheckCircle2, Pencil, PencilLine, Radar, ScanText, Star } from 'lucide-react'
import FAQSchema from '../components-landing/schema/faq-schema'
import { Avatar, AvatarImage } from '../components-landing/ui/avatar'
import LogoCarousel from '../components-landing/logo-carousel'
import { Badge } from '../components-landing/ui/badge'
import TryFree from '../components-landing/commons/try-free'
import { primaryFaqs } from '../lib/faqs'
import FAQ from '../components-landing/faq'
import ExportUI from '../assets/images/exportui.png'
import HumanizerUI from '../assets/images/humanizerui.png'
import HumanizerPageUI from '../assets/images/humanizerpageui.png'
import DetectorUI from '../assets/images/detectorui.png'
import WriterUI from '../assets/images/writerui.png'
import User4 from '../assets/images/user4.jpeg'
import User5 from '../assets/images/user5.jpeg'
import User6 from '../assets/images/user6.jpeg'
import Footer from '../Components/Footer'
import { Editor } from './Humanizer/editor'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import Reviews from '../components-landing/reviews'
import GetStartedButton from '../components-landing/get-started'
import { usePostHog } from 'posthog-js/react'
import { useLocalStorage } from '../helpers/utility'
import { zarazLog } from '../helpers/analytics'

export default function Home() {
	const [bannerOpen] = useLocalStorage('bannerOpen', false)
	const posthog = usePostHog()
	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0,
	})

	useEffect(() => {
		posthog.capture('page-viewed', { page: 'home' })
		window.scrollTo(0, 0)
		zarazLog('PageView')
	}, [])

	return (
		<>
			<FAQSchema faqs={primaryFaqs} />
			<div className="relative">
				<div className="w-screen h-screen bg-gradient-to-b from-white via-[#ba85eb] to-white absolute bottom-[-80px] opacity-30 z-[-10]" />
				<div className={`mx-auto ${bannerOpen ? 'mt-44' : 'mt-28'} w-full max-w-screen-xl px-4 md:mt-44`}>
					<div className="mx-auto w-full max-w-3xl text-center">
						<h1 className="text-4xl leading-tight sm:text-7xl font-[500] text-gray-900">
							Transform Your Writing with AI Magic
						</h1>
						<p className="mt-3 md:text-xl text-lg text-gray-700 mb-8">
							Turn AI-generated content into human text with our leading AI Humanizer. Write faster and boost your
							productivity—all 100% undetectable by TurnItIn and other AI detectors.
						</p>
						<GetStartedButton redirectUrl="/choose" />
						<div className="mt-4 flex flex-row items-center justify-center gap-2">
							<div className="flex flex-row items-center -space-x-2">
								<Avatar className="h-8 w-8 outline outline-2 outline-white">
									<AvatarImage src={User4} alt="User profile picture" />
								</Avatar>
								<Avatar className="h-8 w-8 outline outline-2 outline-white">
									<AvatarImage src={User5} alt="User profile picture" />
								</Avatar>
								<Avatar className="h-8 w-8 outline outline-2 outline-white">
									<AvatarImage src={User6} alt="User profile picture" />
								</Avatar>
							</div>
							<div className="text-gray-700">Join 3,000,000+ writers</div>
						</div>
					</div>
					<div
						className={`mt-10 hidden md:block transform transition-all duration-700 ${
							inView ? 'opacity-100 scale-100' : 'opacity-0 scale-90'
						}`}
						ref={ref}
					>
						<Editor />
					</div>
					<div className="mt-10 block md:hidden">
						<video src="/humanizer.mp4" className="rounded-xl shadow" autoPlay />
					</div>
				</div>
			</div>

			<div className="mx-auto mt-24 w-full max-w-screen-xl px-4">
				<div className="mt-12 text-center">
					<div className="mb-4 flex flex-row justify-center gap-2">
						<CheckCircle2 className="text-slate-500" />
						<h3 className="font-medium text-slate-500 dark:text-slate-400">
							Trusted by Writers At Top Colleges and Universities
						</h3>
					</div>
					<LogoCarousel />
				</div>
				<div className="text-center py-24">
					<div className="mx-auto max-w-4xl px-1">
						{/* <Badge
							size="lg"
							className="gap-2 bg-secondary bg-opacity-30 font-bold uppercase text-primary-800 
						bg-gradient-to-r from-primary-200 to-primary-400"
						>
							<WandSparkles />
							Why Our AI Humanizer?
						</Badge> */}
						<h2 className="mt-3 text-3xl sm:text-5xl text-gray-900">An All-in-One AI Humanizer Tool</h2>
						<p className="mt-6 text-lg text-gray-700 mx-auto max-w-3xl">
							Streamline your content creation from start to finish with our AI humanizer—crafting human-sounding
							content has never been easier.
						</p>
					</div>
					<div className="mx-auto max-w-6xl grid md:grid-cols-2 gap-8 mt-16 px-1">
						{[
							{
								icon: <PencilLine size={20} />,
								title: 'Humanization',
								description:
									'Elevate your content with our AI Humanizer, which transforms dull, mechanical text into engaging, human-like content that resonates with readers.',
							},
							{
								icon: <ScanText size={20} />,
								title: 'Plagiarism',
								description:
									"Ensure originality with Clarify's AI humanizer plagiarism detection—our detector scans your text against an extensive database, guaranteeing that every piece of content you produce is 100% unique.",
							},
							{
								icon: <Radar size={20} />,
								title: 'Detector Bypass',
								description:
									'Our AI humanizer is built to produce human-sounding content that bypasses the leading AI detections on the market.',
							},
							{
								icon: <Star size={20} />,
								title: 'Content Quality',
								description:
									'Create engaging, well-crafted AI humanized content that connects with readers and highlights your insights.',
							},
						].map((feature, index) => (
							<div
								key={index}
								className="flex flex-col gap-4 px-3 py-6 border border-gray-300 bg-gray-50 rounded-xl hover:border-secondary transition-all"
							>
								<div className="text-lg flex items-center gap-2 font-semibold">
									<div className="p-2 bg-secondary rounded-full text-white">{feature.icon}</div> {feature.title}
								</div>
								<div className="text-gray-800 text-left">{feature.description}</div>
							</div>
						))}
					</div>
				</div>
				<div className="mt-24 md:mt-40">
					<div className="mx-auto max-w-4xl text-center">
						{/* <Badge
							size="lg"
							className="gap-2 bg-secondary bg-opacity-30 font-bold uppercase text-primary-700 
						bg-gradient-to-r from-primary-200 to-primary-400"
						>
							<Bot /> Your AI Toolkit
						</Badge> */}
						<h2 className="mt-6 text-4xl sm:text-6xl/tight text-gray-900">Your AI Writing Assistant</h2>
						<p className="mt-6 text-xl text-gray-700">
							Features like our AI Humanizer built to accelerate your writing and boost your productivity.
						</p>
					</div>
					<div className="mt-16 grid grid-cols-1 gap-10 md:grid-cols-2">
						<div>
							<img src={WriterUI} alt="Writer UI" className="rounded" />
							<h3 className="mt-6 text-3xl">AI Writer + Humanizer</h3>
							<p className="mt-2 text-gray-700">
								Generate humanized content within seconds that includes in-text citations from real articles, journals,
								and websites.
							</p>
						</div>
						<div>
							<img src={HumanizerUI} alt="Humanizer UI" className="rounded" />
							<h3 className="mt-6 text-3xl">AI Humanizer + Bypass</h3>
							<p className="mt-2 text-gray-700">
								Transform AI-generated content using our AI Humanizer to pass AI content detectors like TurnItIn.
							</p>
						</div>
						<div>
							<img src={DetectorUI} alt="Detect UI" className="rounded" />
							<h3 className="mt-6 text-3xl">AI Content Detector</h3>
							<p className="mt-2 text-gray-700">
								Write with confidence using our AI Humanizer. Our AI detector to see your if content passes AI checks
								from the leading AI detection tools, including TurnItIn.
							</p>
						</div>
						<div>
							<img src={ExportUI} alt="Export UI" className="rounded" />
							<h3 className="mt-6 text-3xl">Content Export</h3>
							<p className="mt-2 text-gray-700">
								Run your writing through our checks and export your humanized content within seconds of generation.
							</p>
						</div>
					</div>
				</div>
				<div className="mt-24 md:mt-40">
					<Reviews />
				</div>
				<FAQ />
				<div className="my-24 md:mt-40 md:mb-24">
					<TryFree />
				</div>
			</div>
			<Footer />
		</>
	)
}
