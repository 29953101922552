import { BadgePercent, CheckCircle2, CreditCard } from 'lucide-react'
import { Button } from './ui/button'
import { FEATURE_FLAGS, usePricing } from '../hooks/featureFlags'
import { Badge } from './ui/badge'
import { Switch } from './ui/switch'
import { useLocalStorage } from '../helpers/utility'
import useIsPremium from '../hooks/useIsPremium'
import useCreatePortalSession from '../hooks/useBillingPortal'
import useCheckout from '../hooks/checkout'
import { useState } from 'react'
import LoginModal from '../Components/Modals/Login'
import { useAuth } from '../contexts/Auth'
import { useFeatureFlagVariantKey } from 'posthog-js/react'

const unlimitedBenefits = [
	'Unlimited Humanizations',
	'Bypass Advanced AI Detectors',
	'Unlimited AI Content Detection',
	'Unlimited Content Generations',
	'2500 Words per Generation',
]

const PricingTable = () => {
	const noFreeTrialFF = useFeatureFlagVariantKey(FEATURE_FLAGS.NO_FREE_TRIAL)
	const isPremium = useIsPremium()
	const [annualPlan, setAnnualPlan] = useLocalStorage('annualPlan', true)
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const pricing = usePricing()
	const createBillingPortal = useCreatePortalSession()
	const { checkout } = useCheckout()
	const { currentUser } = useAuth() as any

	const onFreeTrialClick = () => {
		if (!currentUser) {
			setLoginModalOpen(true)
			return
		}
		if (isPremium) {
			createBillingPortal()
		} else {
			checkout(annualPlan, true)
		}
	}

	const onUpgradeClick = () => {
		if (!currentUser) {
			setLoginModalOpen(true)
			return
		}
		if (isPremium) {
			createBillingPortal()
		} else {
			checkout(annualPlan, false)
		}
	}

	const freeBenefits =
		noFreeTrialFF === 'test'
			? ['300 AI Humanization words', 'Limited AI Detection Bypass', '250 Words per Generation', 'Limited AI Features']
			: ['600 AI Humanization words', 'Limited AI Detection Bypass', '250 Words per Generation', 'Limited AI Features']

	return (
		<>
			<LoginModal premiumPath open={loginModalOpen} setModalOpen={setLoginModalOpen} annualPlan={annualPlan} />
			<div className="flex flex-col gap-8">
				<div className="flex justify-center gap-5 items-center">
					{/* <Badge variant="destructive" className="text-white hidden md:flex">
						<BadgePercent size={14} className="mr-1" />
						SAVE 25%
					</Badge> */}
					<div className={`${annualPlan ? 'text-gray-700' : 'font-semibold'} text-lg`}>Monthly</div>
					<Switch
						checked={annualPlan}
						onCheckedChange={(checked) => {
							setAnnualPlan(checked)
						}}
					/>
					<div className={`${!annualPlan ? 'text-gray-700' : 'font-semibold'} text-lg`}>Annual</div>
					<Badge variant="destructive" className="text-white hidden md:flex">
						{/* <BadgePercent size={14} className="mr-1" /> */}
						SAVE {pricing.discount}%
					</Badge>
				</div>
				<div className="grid grid-cols-1 items-center gap-6 sm:grid-cols-2">
					<div className="rounded-2xl border bg-card p-6 shadow-lg">
						<h3 className="text-2xl ">Free</h3>
						{noFreeTrialFF !== 'test' && (
							<div className="mt-3 text-gray-700">
								Get full access to all our tools and features at an affordable monthly or annual price.
							</div>
						)}
						<p className="mt-3">
							<span className="text-5xl font-bold text-slate-900 dark:text-white">$0</span> / month
						</p>
						{noFreeTrialFF !== 'test' && (
							<p className="mt-2 text-xs text-slate-500">
								billed as ${annualPlan ? pricing.yearlyTotal : pricing.monthly} / {annualPlan ? 'year' : 'month'} after
								trial
							</p>
						)}
						{noFreeTrialFF !== 'test' && (
							<Button size="xl" variant="outline" className="mt-6 w-full" onClick={onFreeTrialClick}>
								<CreditCard className="mr-2" /> {isPremium ? 'Manage subscription' : 'Start Free Trial'}
							</Button>
						)}
						<ul className="mt-6 flex flex-col gap-0">
							{freeBenefits.map((benefit) => (
								<li className="flex flex-row gap-2">
									<CheckCircle2 className="h-6 w-6" />
									<div className="text-gray-700 text-md">{benefit}</div>
								</li>
							))}
						</ul>
					</div>
					<div className="rounded-2xl border-2 border-secondary bg-card p-6 shadow-lg">
						<h3 className="text-2xl">Unlimited</h3>
						<div className="mt-3 text-gray-700">
							Take your writing productivity and quality to the next level with our unlimited plan.
						</div>
						<p className="mt-3">
							<span className="md:text-4xl text-3xl font-bold text-slate-400 dark:text-white mr-2 line-through">
								${annualPlan ? pricing.yearlyOriginal : pricing.monthlyOriginal}
							</span>
							<span className="md:text-5xl text-4xl font-bold text-slate-900 dark:text-white">
								${annualPlan ? pricing.yearly : pricing.monthly}
							</span>{' '}
							/ month
						</p>
						<p className="mt-2 text-xs text-slate-500">
							billed as ${annualPlan ? pricing.yearlyTotal : pricing.monthly} / {annualPlan ? 'year' : 'month'} after
							trial
						</p>
						<Button size="xl" className="mt-6 w-full" onClick={onUpgradeClick}>
							<CreditCard className="mr-2" /> {isPremium ? 'Manage subscription' : 'Upgrade Plan'}
						</Button>
						<ul className="mt-6 flex flex-col">
							{unlimitedBenefits.map((benefit) => (
								<li className="flex flex-row gap-2">
									<CheckCircle2 className="h-6 w-6 text-secondary" />
									<div className="text-gray-700 text-md">{benefit}</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}

export default PricingTable
