function Loader({ small }: { small?: boolean }) {
	return (
		<div className="flex items-center justify-center">
			<div
				style={{ animationDuration: '1s' }}
				className={`${
					small ? 'w-8 h-8 border-3' : 'w-12 h-12 border-4'
				} border-gray-500 border-dashed rounded-full animate-spin`}
			></div>
		</div>
	)
}

export default Loader
