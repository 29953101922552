import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setLastAggregatePageVisited } from '../redux/systemSlice'
import { DOC_TEMPLATES } from '../constants'
import UserDocs from '../Components/UserDocs/UserDocs'
import { usePostHog } from 'posthog-js/react'
import { useHistory } from 'react-router-dom'
import { LuFileSearch, LuSearchCheck } from 'react-icons/lu'
import { TbPencilMinus } from 'react-icons/tb'
import { FaListOl, FaRegFile } from 'react-icons/fa6'
import { RiRobot2Line } from 'react-icons/ri'
import { HiOutlineDocumentText } from 'react-icons/hi2'
import { PiClipboardText, PiHeadCircuit } from 'react-icons/pi'
import { BiChevronRight } from 'react-icons/bi'
import UpgradeModals from '../Components/Modals/Upgrade'
import useIsPremium from '../hooks/useIsPremium'
import { Button } from '../components-landing/ui/button'
import useNewDocument from '../hooks/useNewDocument'
import { zarazLog } from '../helpers/analytics'

export type templateType = {
	id: string
	title: string
	icon?: JSX.Element
	desc: string
	label?: string
	href?: string
}

export const useOptions = (): templateType[] => {
	const idToDesc = {
		[DOC_TEMPLATES.Writer]: 'Choose a topic, word count, tone, reading level, and more.',
		[DOC_TEMPLATES.Outline]: "Choose a topic and we'll generate an outline for you to complete.",
		[DOC_TEMPLATES.Blank]: 'Start yourself and use our autocomplete tool to guide you.',
		humanizer: 'Paste in text and we will rewrite it to avoid AI detection.',
		detector: 'Paste in text and we will tell you if it was written by AI.',
	}

	return [
		{
			id: DOC_TEMPLATES.Writer,
			title: 'AI Essay Writer',
			icon: <TbPencilMinus size={32} className="mb-2 block" />,
			desc: idToDesc[DOC_TEMPLATES.Writer],
		},
		{
			id: 'humanizer',
			title: 'AI Humanizer',
			icon: <RiRobot2Line size={32} className="mb-2 block" />,
			desc: idToDesc['humanizer'],
			href: '/humanizer',
		},
		{
			id: 'detector',
			title: 'AI Detector',
			icon: <LuFileSearch size={32} className="mb-2 block" />,
			desc: idToDesc['detector'],
			href: '/detector',
		},
		{
			id: DOC_TEMPLATES.Outline,
			title: 'AI Essay Outliner',
			icon: <FaListOl size={26} className="mb-1 block" />,
			desc: idToDesc[DOC_TEMPLATES.Outline],
		},
		{
			id: DOC_TEMPLATES.Blank,
			title: 'Blank Document',
			icon: <FaRegFile size={32} className="mb-2 block" />,
			desc: idToDesc[DOC_TEMPLATES.Blank],
		},
	]
}

const pageName = 'doc'

const ButtonWithArrow = ({ children, ...props }: any) => {
	return (
		<Button {...props} className={`flex items-center group hover:pr-5 pr-0 transition-all ${props.className}`}>
			{children}
			<span className="text-2xl h-4 w-4 group-hover:visible invisible group-hover:opacity-100 relative right-2 bottom-1 group-hover:-right-1 opacity-0 transition-all">
				<BiChevronRight />
			</span>
		</Button>
	)
}

function Doc() {
	const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const isPremium = useIsPremium()
	const dispatch = useDispatch()
	const posthog = usePostHog()
	const history = useHistory()
	const { createDoc, loading, templateID } = useNewDocument()

	useEffect(() => {
		dispatch(setLastAggregatePageVisited({ value: '/choose' }))
	}, [dispatch])

	useEffect(() => {
		window.scrollTo(0, 0)
		zarazLog('PageView')
	}, [])

	const accountModals = useMemo(
		() => (
			<>
				<UpgradeModals
					subscriptionModalOpen={subscriptionModalOpen}
					loginModalOpen={loginModalOpen}
					setSubscriptionModalOpen={(open: boolean) => {
						setSubscriptionModalOpen(open)
					}}
					setLoginModalOpen={(open: boolean) => {
						setLoginModalOpen(open)
					}}
					premiumPath={true}
				/>
			</>
		),
		[subscriptionModalOpen, loginModalOpen]
	)

	const greeting = useMemo(() => {
		const now = new Date()
		const currentHour = now.getHours()
		if (currentHour < 12) {
			return 'Good morning'
		} else if (currentHour < 18) {
			return 'Good afternoon'
		} else {
			return 'Good evening'
		}
	}, [])

	return (
		<main id={pageName} className="px-2 pt-16 pb-36 max-w-[980px] mx-auto">
			{accountModals}
			{!isPremium && (
				<div className="flex justify-center">
					<div
						className="flex gap-2 px-3 py-1 text-sm font-semibold mt-2 mb-8 mx-auto cursor-pointer bg-gray-200 border rounded border-gray-400"
						onClick={() => {
							setSubscriptionModalOpen(true)
							posthog?.capture('nav-upgrade-button-clicked')
						}}
					>
						Using Limited Free Plan <span className="text-link">Upgrade</span>
					</div>
				</div>
			)}
			<h5 className="md:text-4xl text-center mb-10 mt-3 text-3xl">{greeting}, how can I help you write?</h5>
			<div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
				<div className="rounded-xl p-5 flex flex-col gap-4 items-start border border-gray-400 bg-primary-50">
					<PiHeadCircuit size={48} className="transform -scale-x-100" />
					<div className="flex flex-col gap-4 items-start grow">
						<div className="flex flex-col gap-1">
							<div className="text-xl font-semibold">Humanize your AI-written text</div>
							<div className="text-lg text-gray-700">with our AI Detector Bypass Tool</div>
						</div>
						<div className="flex flex-col grow justify-end">
							<ButtonWithArrow variant="shadow" className="text-white" onClick={() => history.push('/humanizer')}>
								Humanize now
							</ButtonWithArrow>
						</div>
					</div>
				</div>
				<div className="rounded-xl p-5 flex flex-col gap-4 items-start border border-gray-400 bg-primary-50">
					<LuSearchCheck size={48} />
					<div className="flex flex-col gap-4 items-start grow">
						<div className="flex flex-col gap-1">
							<div className="text-xl font-semibold">Check for AI-written text</div>
							<div className="text-lg text-gray-700">using our AI Detector</div>
						</div>
						<div className="flex flex-col grow justify-end">
							<ButtonWithArrow variant="shadow" className="text-white" onClick={() => history.push('/detector')}>
								Run an AI check
							</ButtonWithArrow>
						</div>
					</div>
				</div>
				<div className="rounded-xl p-5 flex flex-col gap-4 items-start border border-gray-400">
					<HiOutlineDocumentText size={46} className="transform -scale-x-100" />
					<div className="flex flex-col gap-4 items-start grow">
						<div className="flex flex-col gap-1">
							<div className="text-xl font-semibold">Generate content</div>
							<div className="text-lg text-gray-700">using our AI Writer</div>
						</div>
						<div className="flex flex-col grow justify-end">
							<ButtonWithArrow
								variant="outline"
								onClick={() => {
									posthog.capture('template-chosen', { type: DOC_TEMPLATES.Writer })
									createDoc(DOC_TEMPLATES.Writer)
								}}
							>
								{loading && templateID === DOC_TEMPLATES.Writer ? 'Loading...' : 'Start writing'}
							</ButtonWithArrow>
						</div>
					</div>
				</div>
				<div className="rounded-xl p-5 flex flex-col gap-4 items-start border border-gray-400">
					<PiClipboardText size={48} className="transform -scale-x-100" />
					<div className="flex flex-col gap-4 items-start grow">
						<div className="flex flex-col gap-1">
							<div className="text-xl font-semibold">Generate a thesis and essay outline</div>
							<div className="text-lg text-gray-700">using our AI Outliner</div>
						</div>
						<div className="flex flex-col grow justify-end">
							<ButtonWithArrow
								variant="outline"
								onClick={() => {
									posthog.capture('template-chosen', { type: DOC_TEMPLATES.Outline })
									createDoc(DOC_TEMPLATES.Outline)
								}}
							>
								{loading && templateID === DOC_TEMPLATES.Outline ? 'Loading...' : 'Generate outline'}
							</ButtonWithArrow>
						</div>
					</div>
				</div>
			</div>
			<UserDocs />
		</main>
	)
}

export default Doc
