import LoginModal from './Login'
import Subscriptions from './Subscriptions'
import { useLocalStorage } from '../../helpers/utility'

const UpgradeModals = ({
	subscriptionModalOpen,
	loginModalOpen,
	setSubscriptionModalOpen,
	setLoginModalOpen,
	premiumPath,
	noMoreCredits = false,
	loginVariant,
}: {
	subscriptionModalOpen: boolean
	loginModalOpen: boolean
	setSubscriptionModalOpen: (open: boolean) => void
	setLoginModalOpen: (open: boolean) => void
	premiumPath?: boolean
	noMoreCredits?: boolean
	loginVariant?: 'login' | 'signup'
}) => {
	const [annualPlan] = useLocalStorage('annualPlan', true)
	return (
		<>
			<Subscriptions
				open={subscriptionModalOpen}
				closeModal={() => setSubscriptionModalOpen(false)}
				noMoreCredits={noMoreCredits}
			/>
			<LoginModal
				premiumPath={premiumPath}
				open={loginModalOpen}
				setModalOpen={(open: boolean) => {
					setLoginModalOpen(open)
				}}
				annualPlan={annualPlan}
				loginVariant={loginVariant}
			/>
		</>
	)
}

export default UpgradeModals
