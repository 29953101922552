export interface FAQ {
	question: string
	answer: string
}

export const primaryFaqs: FAQ[] = [
	{
		question: 'Does Clarify offer an annual discount?',
		answer: 'Subscribers to our annual plan receive a 45% discount on monthly prices!',
	},
	{
		question: 'How does Clarify ensure that humanized content is AI-undetectable?',
		answer:
			'Clarify uses the most advanced algorithms available to humanize AI-generated content, making it completely undetectable by leading AI detection tools such as TurnItIn, Originality, and GPTZero.',
	},
	{
		question: 'Why not use ChatGPT?',
		answer:
			'Clarify has been tuned using thousands of research papers and essays to produce the highest quality essays available, something ChatGPT isn’t optimized for. Clarify’s essays can also be humanized to completely avoid AI detectors and plagiarism checks.',
	},
	{
		question: 'Will the quality of my content be affected?',
		answer:
			"No! As Clarify is trained on various different types of writing—including essays, journals, and other kinds of high-quality content—it's capable of matching any writing level and replicating the tone and style of the original content.",
	},
	{
		question: 'Does Clarify pass TurnItIn’s AI and Plagiarism detection?',
		answer:
			'Yes! Clarify consistently passes TurnItIn’s detection. We also verify using third parties with access to TurnItIn instructor accounts.',
	},
]

export const detectorFaqs: FAQ[] = [
	{
		question: 'How does Clarify ensure that humanized content is AI-undetectable?',
		answer:
			'Clarify uses the most advanced algorithms available to humanize AI-generated content, making it completely undetectable by leading AI detection tools such as TurnItIn, Originality, and GPTZero.',
	},
	{
		question: 'Is your AI detector accurate?',
		answer:
			'Yes! Our AI detector is highly accurate, and provides scores from many of the leading AI detection tools available on the market, ensuring you receive accurate and precise results.',
	},
	{
		question: 'Does Clarify store my content after completing an AI check?',
		answer:
			"No—we're dedicated to protecting your privacy! Your data is deleted immediately after we run it through our AI detection process.",
	},
	{
		question: 'Does Clarify pass TurnItIn’s AI and Plagiarism detection?',
		answer:
			'Yes! Clarify consistently passes TurnItIn’s detection. We also verify using third parties with access to TurnItIn instructor accounts.',
	},
]

export const humanizerFaqs: FAQ[] = [
	{
		question: 'Will the AI Humanizer change the ideas in my content?',
		answer:
			"Clarify's AI Humanizer transforms your AI-generated content by adding a human touch, keeping your original ideas and key points intact while making your writing more engaging and authentic.",
	},
	{
		question: 'Can I adjust the intensity of AI humanization applied to my content?',
		answer:
			"Absolutely! Clarify's AI Humanizer offers three levels of humanization intensity to match your needs: Light, Medium, and Strong. Light: Ideal for blogs, professional writing, or any content that needs to sound natural and also be clear and easy to understand. Medium: Strikes the perfect balance between human-like nuance and clarity, making it the perfect option for essays and professional copy. This setting effectively bypasses the leading AI detectors. Strong: Applies the highest level of humanization, making it the most effective at bypassing AI detectors. This setting is great for projects where an informal, conversational tone is appropriate—like in social media or casual blogs. With our AI humanizer, you have full control over the level of humanization added to your content, allowing you to tailor your writing to perfectly match your desired use case.",
	},
	{
		question: "Does Clarify's AI humanizer support languages other than English?",
		answer:
			"Clarify's AI humanizer supports a multitude of different languages, including French, Spanish, and Portuguese.",
	},
	{
		question: 'What types of content can I use the AI Humanizer on?',
		answer:
			'Our AI Humanizer expertly handles all types of content, including essays, articles, blog posts, reports, and marketing materials. Whether you’re crafting academic papers or writing content for your next blog, our AI humanizer helps you transform your AI-generated text into compelling, human-sounding content.',
	},
	{
		question: 'How many words can I humanize at once?',
		answer:
			'With the Free Plan, our AI humanizer lets you can convert up to 600 words into natural, human-like text and enjoy unlimited AI detection at no cost. If you’re looking for more expansive capabilities, our Unlimited Plan allows you to AI humanize up to 3000 words per session—perfect for those who need unrestricted usage.',
	},
	{
		question: 'Can I adjust my content after it’s been humanized?',
		answer:
			'After your content has been humanized with our AI humanizer, it appears in a text editor—allowing you to adjust, re-humanize, or export as desired. Refine your content with our AI humanizer until it’s perfect!',
	},
	{
		question: 'How does Clarify keep my data secure?',
		answer:
			'We employ end-to-end encryption to ensure your humanized content remains secure—your data security is our top priority!',
	},
]
