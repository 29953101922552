import './_Thanks.scss'
import Modal from './Modal'
import { Button } from '../../components-landing/ui/button'
import { useSelector } from 'react-redux'
import { selectUser } from '../../redux/systemSlice'
import useLoadingDots from '../../hooks/useLoadingDots'
import { CircleX, CreditCard, LoaderCircle } from 'lucide-react'
import useCheckout from '../../hooks/checkout'

const TestPaymentModal = () => {
	const { checkout, loading } = useCheckout()
	const user = useSelector(selectUser)
	const loadingDots = useLoadingDots(user.testPaymentIntentStatus !== 'FAILED')

	if (user.isOnFreeTrial && user.testPaymentIntentStatus === 'SUCCEEDED') {
		return null
	}

	return (
		<Modal open={true} closeModal={() => {}} portalClassName="thanks" omitClose>
			{user.testPaymentIntentStatus === 'FAILED' ? (
				<div className="flex flex-col gap-4 items-center">
					<CircleX className="p-2 bg-red-100 rounded-full w-10 h-10 text-red-600" />
					<div className="text-2xl text-center font-semibold">🚨 Payment Failed!</div>
					<div className="text-gray-700 text-center">
						We were unable to verify your payment details. Please try again or contact support@clarify.ai
					</div>
					<Button
						onClick={() => {
							checkout(user.isAnnualSubscriber, true)
						}}
						className="w-full mt-4"
					>
						{loading ? <LoaderCircle className="h-5 w-5 animate-spin duration-500" /> : 'Try Again'}
					</Button>
				</div>
			) : (
				<div className="flex flex-col gap-4 items-center pt-10 pb-12">
					<CreditCard className="p-2 bg-primary-100 rounded-full w-10 h-10 text-primary-700" />
					<div className="text-2xl text-center font-semibold">Verifying Payment Method{loadingDots}</div>
				</div>
			)}
		</Modal>
	)
}

export default TestPaymentModal
