import { useSelector } from 'react-redux'
import { DocumentState } from '../../redux/types'
import { selectDocumentState } from '../../redux/docSlice'
import { Editor } from '@tiptap/core'
import Button from '../Buttons'
import { BubbleMenu } from '@tiptap/react'
import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { findParentNodeOfType } from 'prosemirror-utils'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'

const Autocomplete = ({
	docID,
	editor,
	showAutocompleteMenu,
	setShowAutocompleteMenu,
}: {
	docID: string
	editor: Editor | null
	showAutocompleteMenu: boolean
	setShowAutocompleteMenu: (show: boolean) => void
}) => {
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const [acceptAutocompleteNode, setAcceptAutocompleteNode] = useState<any>(null)

	const parentNode = useMemo(() => {
		if (!editor) return null
		const { state } = editor
		const { selection } = state
		const paragraphNode = findParentNodeOfType(state.schema.nodes.paragraph)(selection)
		return paragraphNode
	}, [editor?.state.selection])

	const editorSelectionAtEnd = useMemo(() => {
		if (!editor) return null
		const { state } = editor
		const { selection } = state

		// Ensure we have a text selection
		if (selection.empty) {
			if (parentNode) {
				const paragraphEndPos = parentNode.pos + parentNode.node.nodeSize
				// @ts-ignore
				const cursorPos = selection.$cursor.pos

				// Check if cursor position is at the end of the paragraph
				return cursorPos === paragraphEndPos - 1
			}
		}
		return false
	}, [editor?.state.selection])

	useEffect(() => {
		setShowAutocompleteMenu(false)

		if (!editor || !documentState || !editorSelectionAtEnd) return

		// Function to simulate Tab key press
		const simulateTabKeyPress = () => {
			const event = new KeyboardEvent('keydown', {
				key: 'Tab',
				code: 'Tab',
				keyCode: 9, // Deprecated, but still added for cross-browser compatibility
				which: 9, // Deprecated, but still added for cross-browser compatibility
				bubbles: true,
				cancelable: true,
			})

			// Ensure the popup logic and editor focus are correct
			editor?.chain().focus().run()

			const activeElement = document?.activeElement
			if (activeElement) {
				activeElement.dispatchEvent(event)
			}
		}
		const debouncedTabKeyPress = debounce(simulateTabKeyPress, 500)
		debouncedTabKeyPress()

		// Cleanup function
		return () => {
			debouncedTabKeyPress.cancel()
		}
	}, [documentState.content, editorSelectionAtEnd, parentNode])

	useEffect(() => {
		if (!setAcceptAutocompleteNode || acceptAutocompleteNode !== parentNode) return
		// Function to simulate Tab key press
		const simulateTabKeyPress = () => {
			const event = new KeyboardEvent('keydown', {
				key: 'Tab',
				code: 'Tab',
				keyCode: 9, // Deprecated, but still added for cross-browser compatibility
				which: 9, // Deprecated, but still added for cross-browser compatibility
				bubbles: true,
				cancelable: true,
			})

			// Ensure the popup logic and editor focus are correct
			editor?.chain().focus().run()

			const activeElement = document?.activeElement
			if (activeElement) {
				// Check if activeElement exists and can handle the event
				activeElement.dispatchEvent(event)
			} else {
				console.warn('No active element to dispatch the event.')
			}
		}
		editor?.chain().focus().run()
		const debouncedTabKeyPress = debounce(simulateTabKeyPress, 10)
		debouncedTabKeyPress()

		// Cleanup function
		return () => {
			debouncedTabKeyPress.cancel()
		}
	}, [acceptAutocompleteNode, parentNode])

	if (
		!editor ||
		!documentState ||
		documentState.isGenerating ||
		documentState.isTypingAddMore ||
		documentState.isTypingEssay
	) {
		return null
	}

	return (
		<div>
			<BubbleMenu
				editor={editor}
				tippyOptions={{ duration: 100 }}
				shouldShow={({ editor }) => {
					return true
				}}
			>
				<div className={`border bg-white px-3 py-2 rounded shadow-lg  ${showAutocompleteMenu ? 'block' : 'hidden'}`}>
					<Button
						type="primary"
						onClick={() => setAcceptAutocompleteNode(parentNode)}
						className={`px-2 py-1 border border-gray-400 rounded text-md hover:bg-gray-100 `}
					>
						<div className="flex gap-2 items-center">
							<span>Accept</span>
							<div className={`text-[9px]  p-0.5 font-semibold rounded-sm bg-gray-300`}>TAB</div>
						</div>
					</Button>
				</div>
			</BubbleMenu>
		</div>
	)
}

export default Autocomplete
