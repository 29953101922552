import { Tag } from 'lucide-react'
import { Badge } from '../components-landing/ui/badge'
import PricingTable from '../components-landing/pricing-table'
import TryFree from '../components-landing/commons/try-free'
import Footer from '../Components/Footer'

export default function Pricing() {
	return (
		<>
			<div className="mx-auto mt-28 w-full max-w-screen-xl px-4 md:mt-32">
				<div className="mx-auto w-full max-w-[936px] text-center">
					{/* <Badge size="lg" className="gap-2 bg-success-default bg-opacity-20 font-bold uppercase text-success-default">
						<Tag /> Pricing
					</Badge> */}
					<h1 className="mt-4 text-4xl sm:text-6xl/tight">Pick the plan that works for you</h1>
					<p className="mt-6 text-xl text-gray-700">
						Get full access to all our tools and features at an affordable monthly or annual price. No hidden fees or
						add-ons. Risk-free—cancel anytime.
					</p>
					<div className="mt-8 text-start">
						<PricingTable />
					</div>
				</div>
				<div className="my-24 md:my-40">
					<TryFree />
				</div>
			</div>
			<Footer />
		</>
	)
}
