import './_GoPremium.scss'
import { LoaderCircle, Rocket, Sparkles } from 'lucide-react'
import { Button } from '../../components-landing/ui/button'
import Modal from './Modal'
import { FaCheckCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser, setUser } from '../../redux/systemSlice'
import { useState } from 'react'
import Thanks from './Thanks'

const GoPremium = ({ open, setModalOpen }: { open: boolean; setModalOpen: (open: boolean) => void }) => {
	const [showSuccessModal, setShowSuccessModal] = useState(false)
	const [checkingPayment, setCheckingPayment] = useState(false)
	const user = useSelector(selectUser)
	const dispatch = useDispatch()

	const upgradeFreeTrial = () => {
		setCheckingPayment(true)
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				user_id: user.id,
			}),
		}

		fetch(process.env.REACT_APP_API_ROOT + '/payments/upgrade_free_trial/', requestOptions).then(() => {
			dispatch(setUser({ ...user, isOnFreeTrial: false }))
			setCheckingPayment(false)
			setModalOpen(false)
			setShowSuccessModal(true)
		})
	}

	if (showSuccessModal) {
		return <Thanks open={true} />
	}

	return (
		<Modal
			open={open}
			closeModal={() => {
				setModalOpen(false)
			}}
			portalClassName="go-premium"
		>
			<div className="flex flex-col gap-4 items-center">
				<div className="flex justify-center items-center p-2 bg-primary-100 rounded-full w-10 h-10 text-primary-700 text-lg">
					<Rocket />
				</div>
				<div className="text-2xl text-center font-semibold">Get Unlimited Words Now!</div>
				<div className="text-gray-700 text-center">
					We hope you’re enjoying Clarify AI! You’ve reached the 600-word limit for your free trial, but your content
					humanizations don’t have to end here. Upgrade to our <b className="text-black">Unlimited</b> Plan and gain
					access to unlimited, high-quality humanizations that keep your content engaging, natural-sounding, and
					completely undetectable.
				</div>
				<div className="bg-primary-50 p-4 rounded-xl flex flex-col gap-4 w-full">
					<div className="flex items-center gap-2">
						<Sparkles className="text-primary-600" />
						<div className="font-semibold text-sm">
							As an Unlimited user, you’ll enjoy unlimited access to all our powerful features:
						</div>
					</div>
					{[
						'AI writer generations',
						'Humanizer and AI Detector Bypass',
						'AI Detector',
						'Expanded 2500 word limit per process',
						'Premium-only features',
					].map((feature, index) => (
						<div key={index} className="flex items-center gap-4">
							<FaCheckCircle className="text-primary-600" />
							<div className="text-gray-800 text-sm uppercase font-semibold">{feature}</div>
						</div>
					))}
				</div>
				<div className="text-gray-700 text-sm text-center">
					If you wish to switch to a different plan, please first cancel your current subscription and then upgrade to
					the new plan.
				</div>
				<div className="grid grid-cols-2 gap-3 w-full">
					<Button
						onClick={() => {
							setModalOpen(false)
						}}
						className="w-full"
						variant="outline"
					>
						Not yet
					</Button>
					<Button onClick={upgradeFreeTrial} className="w-full" disabled={checkingPayment}>
						{checkingPayment ? <LoaderCircle className="h-5 w-5 animate-spin duration-500" /> : 'Go Unlimited'}
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default GoPremium
