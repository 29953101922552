import { Star } from 'lucide-react'
import { Badge } from './ui/badge'
import { Avatar, AvatarImage } from './ui/avatar'
import User1 from '../assets/images/user1.jpeg'
import User2 from '../assets/images/user2.jpeg'
import User3 from '../assets/images/user3.jpeg'
import { BiSolidBadgeCheck } from 'react-icons/bi'

const Reviews = () => {
	return (
		<>
			<div className="mx-auto max-w-4xl text-center">
				{/* <Badge
					size="lg"
					className="gap-2 font-bold uppercase text-amber-800 bg-gradient-to-r from-yellow-200 to-orange-400"
				>
					<Star /> Reviews
				</Badge> */}
				<h2 className="mt-6 text-4xl sm:text-6xl/tight text-gray-900">What People Say About Clarify</h2>
				<p className="mt-6 text-xl">
					Generate essays, research papers, articles and other types of content within seconds with our AI Humanizer—all
					completely AI-undetectable.
				</p>
			</div>
			{/* <div className="mt-10 grid grid-cols-1 gap-8 sm:grid-cols-3">
						<div className="aspect-[9/16] max-h-[80vh] w-full bg-slate-200 dark:bg-slate-700"></div>
						<div className="aspect-[9/16] max-h-[80vh] w-full bg-slate-200 dark:bg-slate-700"></div>
						<div className="aspect-[9/16] max-h-[80vh] w-full bg-slate-200 dark:bg-slate-700"></div>
					</div> */}
			<div className="mx-auto mt-16 grid max-w-3xl grid-cols-3 items-start gap-8 xl:max-w-full">
				<div className="col-span-4 rounded-2xl border bg-card p-6 shadow-lg xl:col-span-1">
					<blockquote className="text-lg text-slate-900 dark:text-slate-100 sm:text-xl">
						"I wasted so much time looking for a good humanizer before I found Clarify. It makes my writing process so
						straightforward and efficient now knowing that I don’t have to worry about AI detectors anymore."
					</blockquote>
					<div className="mt-5 flex flex-row items-center gap-2 xl:mt-16">
						<Avatar className="h-10 w-10">
							<AvatarImage src={User1} alt="User profile picture" />
						</Avatar>
						<div>
							<div className="font-semibold text-slate-900 dark:text-slate-100">Sarah W.</div>
							{/* <div className="text-sm text-primary-600 flex gap-1">
								<BiSolidBadgeCheck className="inline-block text-lg" />
								Verified Purchase
							</div> */}
						</div>
					</div>
				</div>
				<div className="col-span-4 rounded-2xl border bg-card p-6 shadow-lg md:col-span-1 xl:order-first xl:col-span-1">
					<blockquote className="text-lg text-slate-700 dark:text-slate-300">
						“Clarify helps me write my essays so much faster, I can generate and humanize an entire essay in seconds. I
						don’t spend hours and hours writing my essays and assignments anymore!”
					</blockquote>
					<div className="mt-5 flex flex-row items-center gap-2">
						<Avatar className="h-10 w-10">
							<AvatarImage src={User2} alt="User profile picture" />
						</Avatar>
						<div>
							<div className="font-semibold text-slate-900 dark:text-slate-100">Alex H.</div>
							{/* <div className="text-sm text-primary-600 flex gap-1">
								<BiSolidBadgeCheck className="inline-block text-lg" />
								Verified Purchase
							</div> */}
						</div>
					</div>
				</div>
				<div className="col-span-4 rounded-2xl border bg-card p-6 shadow-lg md:col-span-2 xl:col-span-1">
					<blockquote className="text-lg text-slate-700 dark:text-slate-300">
						“Clarify's AI Humanizer was very friendly and had easy to use options to help me with my essays!”
					</blockquote>
					<div className="mt-5 flex flex-row items-center gap-2">
						<Avatar className="h-10 w-10">
							<AvatarImage src={User3} alt="User profile picture" />
						</Avatar>
						<div>
							<div className="font-semibold text-slate-900 dark:text-slate-100">Jordan B.</div>
							{/* <div className="text-sm text-primary-600 flex gap-1">
								<BiSolidBadgeCheck className="inline-block text-lg" />
								Verified Purchase
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Reviews
