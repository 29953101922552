import { useEffect, useState, useMemo } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import Button from '../Buttons'
import { CONSTANTS } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { selectShowNav, setShowNav } from '../../redux/systemSlice'
import { selectDocumentState } from '../../redux/docSlice'
import { useAuth } from '../../contexts/Auth'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { htmlToString, useLocalStorage } from '../../helpers/utility'
import { DocumentState } from '../../redux/types'
import Detector from './Detector'
import { useDocId } from '../../hooks/docID'
import AccountModal from '../Modals/Account'
import Subscriptions from '../Modals/Subscriptions'
import WordCount from './WordCount'
import { usePostHog } from 'posthog-js/react'
import LoginModal from '../Modals/Login'
import { FaCheck } from 'react-icons/fa6'
import { MdContentCopy } from 'react-icons/md'
import Grader from './Grader'
import useIsPremium from '../../hooks/useIsPremium'
import ToolCredits from '../ToolCredits'
import Arrow from '../Arrow'
import PageHeader from '../../components-landing/page-header'
import Logo from '../Logo'

const baseClassNames = 'flex justify-between items-center mx-auto md:h-auto px-4 py-2 md:py-3 md:px-auto'

function Navbar(props: RouteComponentProps) {
	const [copied, setCopied] = useState(false)
	const [accountModalOpen, setAccountModalOpen] = useState(false)
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
	const [annualPlan] = useLocalStorage('annualPlan', true)
	const dispatch = useDispatch()
	const showNavRedux = useSelector(selectShowNav)
	const docID = useDocId()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const pathname = window.location.pathname.split('?')[0]
	const posthog = usePostHog()
	const { currentUser } = useAuth() as any
	const [isPremiumPath, setIsPremiumPath] = useState(false)
	const isPremium = useIsPremium()

	const trimmedPathname = pathname.replace(/\/$/, '')

	//Control showing the nav bar for user mouse move and scroll
	useEffect(() => {
		const onMouseMove = () => {
			dispatch(setShowNav({ value: true }))
		}
		const onScroll = () => {
			if (trimmedPathname === 'create') {
				return
			}
			dispatch(setShowNav({ value: true }))
		}

		window.addEventListener('scroll', onScroll)
		window.addEventListener('mousemove', onMouseMove)
		return () => {
			window.removeEventListener('mousemove', onMouseMove)
			window.removeEventListener('scroll', onScroll)
		}
	}, [trimmedPathname])

	useEffect(() => {
		if (copied) {
			const timeoutId = setTimeout(() => {
				setCopied(false)
			}, 2000)
			return () => clearTimeout(timeoutId)
		}
	}, [copied])

	const handleCopyClick = () => {
		if (documentState.isGenerating || documentState.isTypingAddMore || documentState.isTypingEssay) return
		setCopied(true)
	}

	const copyButton = useMemo(() => {
		if (!documentState) return
		return (
			<CopyToClipboard text={htmlToString(documentState.content ?? '')} onCopy={handleCopyClick}>
				<div
					className={`relative h-6 md:mr-4 mt-0.5 ${
						documentState.isGenerating ||
						documentState.isTypingAddMore ||
						documentState.isTypingEssay ||
						documentState.content === CONSTANTS.EMPTY_DOCUMENT ||
						documentState.content === ''
							? 'pointer-events-none'
							: 'cursor-pointer'
					}`}
					style={{ width: '30px' }}
					onClick={() => posthog?.capture('copy-button-clicked')}
				>
					<span>
						<FaCheck
							className={`absolute transition-opacity top-0.5 h-5 left-0 ${copied ? 'opacity-100' : 'opacity-0'}`}
						/>
						<MdContentCopy
							className={`absolute transition-opacity top-0 h-6 left-0 text-lg ${copied ? 'opacity-0' : 'opacity-100'}`}
						/>
					</span>
				</div>
			</CopyToClipboard>
		)
	}, [copied, documentState, posthog])

	function docNavbarCTAFunc() {
		if (currentUser) {
			setAccountModalOpen(true)
		} else {
			setLoginModalOpen(true)
		}
	}

	const docNavbar = {
		desktop: (
			<div id="desktopNavbar" className={`${baseClassNames} doc ` + (showNavRedux ? 'visible' : '')}>
				<Logo small />
				<div className="flex items-center gap-2">
					<Button type="tertiary" onClick={docNavbarCTAFunc}>
						<span>{currentUser ? 'Account' : 'Log in'}</span>
					</Button>
					{/* {!currentUser && (
						<Button type="tertiary" onClick={docNavbarCTAFunc}>
							<span>SIGN UP</span>
						</Button>
					)} */}
				</div>
			</div>
		),
		mobile: (
			<div id="mobileNavbar" className={`${baseClassNames} doc ` + (showNavRedux ? 'visible' : '')}>
				<div className="flex justify-between w-full items-center">
					<Logo small />
					<div className="flex items-center gap-2">
						<Button type="tertiary" onClick={docNavbarCTAFunc}>
							<span>{currentUser ? 'Account' : 'Log in'}</span>
						</Button>
						{/* {!currentUser && (
							<Button type="tertiary" className="hover:bg-secondary" onClick={docNavbarCTAFunc}>
								<span>SIGN UP</span>
							</Button>
						)} */}
					</div>
				</div>
			</div>
		),
	}

	const newDocNavbar = {
		desktop: (
			<div id="desktopNavbar" className={`${baseClassNames} doc ` + (showNavRedux ? 'visible' : '')}>
				<Logo small />
			</div>
		),
		mobile: (
			<div id="mobileNavbar" className={`${baseClassNames} doc ` + (showNavRedux ? 'visible' : '')}>
				<div className="flex justify-between w-full items-center">
					<Logo small />
				</div>
			</div>
		),
	}

	const createNavbar = {
		desktop: (
			<div
				id="desktopNavbar"
				className={
					`${baseClassNames} group transition-opacity duration-500 create ` +
					(showNavRedux ? 'opacity-100' : 'opacity-0')
				}
			>
				<Link to={CONSTANTS.DOC_PATH} className="h-8 overflow-hidden">
					<span className="text-[30px] rotate-180 inline-block relative">
						<Arrow />
					</span>
				</Link>
				{documentState && documentState.setup && !documentState.isGenerating && (
					<>
						<div className="desktopNavRight flex items-center">
							<span className="text-gray-600 mr-8 opacity-0 group-hover:opacity-100 transition-opacity">
								Saves automatically
							</span>
							{!documentState.isGenerating && <WordCount />}
							{copyButton}
							<div className="mr-4">
								<Grader />
							</div>
							{isPremium && <Detector />}
							{!isPremium && (
								<Button
									type="tertiary"
									onClick={() => {
										setSubscriptionModalOpen(true)
										posthog?.capture('nav-upgrade-button-clicked')
										setIsPremiumPath(true)
									}}
								>
									Upgrade
								</Button>
							)}
						</div>
					</>
				)}
			</div>
		),
		mobile: (
			<div id="mobileNavbar" className={`${baseClassNames} create `}>
				<div className="flex justify-between w-full items-center">
					<Link to={CONSTANTS.DOC_PATH} className="h-8 overflow-hidden">
						<span className="text-[25px] rotate-180 inline-block relative">
							<Arrow />
						</span>
					</Link>
					{documentState?.setup && isPremium && !documentState?.isGenerating && (
						<div className="flex md:gap-3 gap-1">
							{isPremium && copyButton}
							<Grader />
							{isPremium && <Detector />}
						</div>
					)}
					{!isPremium && documentState && documentState.setup && !documentState.isGenerating && (
						<div className="flex gap-2">
							{copyButton}
							<Grader />
							<Button
								type="tertiary"
								onClick={() => {
									setSubscriptionModalOpen(true)
									posthog?.capture('nav-upgrade-button-clicked')
									setIsPremiumPath(true)
								}}
								className="px-2"
							>
								Upgrade
							</Button>
						</div>
					)}
				</div>
			</div>
		),
	}

	const toolNavbar = {
		desktop: (
			<div
				id="desktopNavbar"
				className={
					`${baseClassNames} group transition-opacity duration-500 create ` +
					(showNavRedux ? 'opacity-100' : 'opacity-0')
				}
			>
				<Link to={CONSTANTS.DOC_PATH} className="h-8 overflow-hidden">
					<span className="text-[30px] rotate-180 inline-block relative">
						<Arrow />
					</span>
				</Link>
				{documentState && documentState.setup && !documentState.isGenerating && (
					<>
						<div className="desktopNavRight flex items-center gap-3">
							<ToolCredits />
							{!isPremium && (
								<Button
									type="tertiary"
									onClick={() => {
										setSubscriptionModalOpen(true)
										posthog?.capture('nav-upgrade-button-clicked')
										setIsPremiumPath(true)
									}}
								>
									Upgrade
								</Button>
							)}
						</div>
					</>
				)}
			</div>
		),
		mobile: (
			<div id="mobileNavbar" className={`${baseClassNames} create `}>
				<div className="flex justify-between w-full items-center">
					<Link to={CONSTANTS.DOC_PATH} className="h-8 overflow-hidden">
						<span className="text-[25px] rotate-180 inline-block relative">
							<Arrow />
						</span>
					</Link>
					{!isPremium && documentState && documentState.setup && !documentState.isGenerating && (
						<Button
							type="tertiary"
							onClick={() => {
								setSubscriptionModalOpen(true)
								posthog?.capture('nav-upgrade-button-clicked')
								setIsPremiumPath(true)
							}}
							className="px-2"
						>
							Upgrade
						</Button>
					)}
				</div>
			</div>
		),
	}

	const navigation = [
		{ path: 'choose', nav: docNavbar },
		{ path: 'create', nav: createNavbar },
		{ path: 'humanizer', nav: toolNavbar },
		{ path: 'detector', nav: toolNavbar },
		{ path: 'new', nav: newDocNavbar },
	]

	let navBar = docNavbar

	for (let i = 0; i < navigation.length; i++) {
		if (trimmedPathname.includes(navigation[i].path)) {
			navBar = navigation[i].nav
			break
		}
	}

	return (
		<>
			<Subscriptions
				open={subscriptionModalOpen}
				closeModal={() => {
					setSubscriptionModalOpen(false)
				}}
			/>
			<AccountModal isOpen={accountModalOpen} closeModal={() => setAccountModalOpen(false)} />
			<LoginModal
				open={loginModalOpen}
				setModalOpen={setLoginModalOpen}
				premiumPath={isPremiumPath}
				annualPlan={annualPlan}
			/>
			{['', '/pricing', '/products', '/ai-detector', '/ai-humanizer'].includes(trimmedPathname) ? (
				<PageHeader />
			) : (
				<div className="bg-white w-full fixed top-0 z-20">
					<div className="block md:hidden">{navBar.mobile}</div>
					<div className="hidden md:block">{navBar.desktop}</div>
				</div>
			)}
		</>
	)
}

export default withRouter(Navbar)
