import { useState } from 'react'
import { useAuth } from '../../../contexts/Auth'
import { useDispatch } from 'react-redux'
import { setUserLoading } from '../../../redux/systemSlice'
import { setUser } from '@sentry/react'
import * as Sentry from '@sentry/react'
import { useCookies } from 'react-cookie'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import { isValidEmail } from '../../../helpers/utility'
import { signInWithGooglePopup } from '../../../firebase'
import Input from '../../Input'
import { zarazLog } from '../../../helpers/analytics'
import VerificationCodeInput from './VerificationCodeInput'
import { Button } from '../../../components-landing/ui/button'
import { ArrowLeft, BadgeCheck } from 'lucide-react'
import { FEATURE_FLAGS } from '../../../hooks/featureFlags'

const errorToText = {
	'email-already-in-use': 'Email already in use.',
	unknown: 'Unknown error, please try again.',
}

const SignupContent = ({
	setVariant,
	setModalOpen,
	premiumPath,
	setGoToCheckout,
	redirectUrl,
}: {
	setVariant?: (variant: 'login' | 'signup') => void
	setModalOpen: (open: boolean) => void
	premiumPath?: boolean
	setGoToCheckout: (humanizerUpgrade: boolean) => void
	redirectUrl?: string
}) => {
	const emailVerificationFF = useFeatureFlagVariantKey(FEATURE_FLAGS.EMAIL_VERIFICATION)
	const [code, setCode] = useState(['', '', '', ''])
	const [verificationStep, setVerificationStep] = useState<boolean>(false)
	const [validationCode, setValidationCode] = useState<number | undefined>(undefined)
	const [invalidCode, setInvalidCode] = useState<boolean>(false)
	const [emailInput, setEmailInput] = useState<string | undefined>('')
	const [passwordInput, setPasswordInput] = useState<string | undefined>('')
	const [error, setError] = useState<undefined | 'email-already-in-use' | 'unknown'>(undefined)
	const { signup, setCurrentUser } = useAuth() as any
	const [cookies, setCookie] = useCookies(['docs'])
	const dispatch = useDispatch()
	const posthog = usePostHog()

	async function createUser(uid: string, email: string) {
		if (!uid || !email) throw Error('Called /user/create/ with missing uid and/or email')
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				email,
				uid,
				existingDocs: cookies?.docs?.length > 0 ? cookies.docs : [],
				secondaryClient: true,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/user/create/', requestOptions)
			.then((res) => res.json())
			.then((result) => {
				dispatch(setUser(result))
				setCookie('docs', JSON.stringify([]))
			})
			.catch((err) => Sentry.captureException(err))
	}

	async function createCode() {
		// setVerificationCodeLoading(true)
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				email: emailInput,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/user/verify-email/', requestOptions)
			.then((res) => res.json())
			.then((result) => {
				// setVerificationCodeLoading(false)
				setValidationCode(result.code)
				setVerificationStep(true)
			})
			.catch((err) => {
				Sentry.captureException(err)
				// setVerificationCodeLoading(false)
			})
	}

	const loginGoogleUser = async () => {
		const response = await signInWithGooglePopup()
		if (!response) return
		posthog.capture('signup', { method: 'google' })

		zarazLog('signup', { email: response.user.email })
		setCurrentUser(response.user)

		if (premiumPath) {
			setGoToCheckout(true)
		}
		setModalOpen(false)
		if (redirectUrl) {
			window.location.href = redirectUrl
		}
	}

	async function submitForm() {
		if (!verificationStep && emailVerificationFF === 'test') {
			createCode()
			setVerificationStep(true)
			return
		}
		if (parseInt(code.join('')) + 1322 !== validationCode && emailVerificationFF === 'test') {
			setInvalidCode(true)
			return
		}

		try {
			const formattedEmailInput = emailInput?.toLowerCase() ?? ''
			zarazLog('signup', { email: formattedEmailInput })
			const result = await signup(formattedEmailInput, passwordInput)
			setCookie('docs', [])
			dispatch(setUserLoading({ value: true }))
			await createUser(result.user.uid, formattedEmailInput)
			posthog.capture('signup', { method: 'email' })

			setCurrentUser(result.user)
			dispatch(setUserLoading({ value: false }))

			if (premiumPath) {
				setGoToCheckout(true)
				return
			}
			setModalOpen(false)
			if (redirectUrl) {
				window.location.href = redirectUrl
			}
		} catch (error: any) {
			Sentry.captureException(error)
			if (error.code === 'auth/email-already-in-use') {
				setVerificationStep(false)
				setError('email-already-in-use')
			} else {
				setVerificationStep(false)
				setError('unknown')
			}
		}
	}

	if (verificationStep) {
		return (
			<div className="flex flex-col gap-4">
				<div className="text-2xl font-semibold text-center">Verify Your Email</div>
				<div className="text-center">
					We just sent a 4-digit code to <b>{emailInput}</b>, enter it below:
				</div>
				<VerificationCodeInput code={code} setCode={setCode} invalid={invalidCode} setInvalidCode={setInvalidCode} />
				<div className="text-center text-gray-700">
					Don't see a code?{' '}
					<span className="text-link cursor-pointer" onClick={createCode}>
						Resend email
					</span>
				</div>
				<Button onClick={submitForm} disabled={code.includes('') || code.length !== 4 || invalidCode}>
					<div className="flex justify-center items-center gap-2">
						<BadgeCheck size={20} />
						Verify Email
					</div>
				</Button>
				<div
					className="text-center text-link cursor-pointer flex items-center gap-1 justify-center"
					onClick={() => setVerificationStep(false)}
				>
					<ArrowLeft size={20} />
					Back to login
				</div>
			</div>
		)
	}

	return (
		<>
			<div className="flex flex-col gap-2 md:mb-4">
				<h5 className="text-2xl">Sign up</h5>
				<p className="text-[16px] text-gray-600 mb-0">Create your free account with Clarify</p>
			</div>
			<div className="flex flex-col gap-9 pb-5 pt-5 md:pt-0">
				<Button
					variant="ghost"
					className="w-full flex justify-center items-center border px-3 py-3 gap-3 border-gray-500 hover:bg-gray-100 bg-white"
					onClick={loginGoogleUser}
				>
					<div className="gsi-material-button-icon w-5">
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
							<path
								fill="#EA4335"
								d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
							></path>
							<path
								fill="#4285F4"
								d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
							></path>
							<path
								fill="#FBBC05"
								d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
							></path>
							<path
								fill="#34A853"
								d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
							></path>
							<path fill="none" d="M0 0h48v48H0z"></path>
						</svg>
					</div>
					<div className="text-center font-bold">Continue with Google</div>
				</Button>
				<div className="h-0 text-center border border-b-0 border-gray-600">
					<p className="text-sm text-gray-700 relative bottom-[11px] ">
						<span className="bg-white px-2">Or continue with</span>
					</p>
				</div>
				<div className="flex flex-col gap-6">
					<div>
						<label htmlFor="enter-email-addres" className="text-sm font-semibold pb-1 inline-block">
							Email address
						</label>
						<Input
							value={emailInput ?? ''}
							onChange={(e) => {
								setEmailInput(e.target.value)
								setError(undefined)
							}}
							type="email"
							id="enter-email-address"
							placeholder="you@example.com"
						/>
					</div>
					<div>
						<label htmlFor="password" className="text-sm font-semibold pb-1 inline-block">
							Password
						</label>
						<Input
							value={passwordInput ?? ''}
							onChange={(e) => {
								setPasswordInput(e.target.value)
								setError(undefined)
							}}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									submitForm()
								}
							}}
							type="password"
							id="password"
							placeholder="Password"
						/>
					</div>
					{error && <div className="text-red-600 text-center">{errorToText[error]}</div>}
					<div className="flex flex-col gap-2">
						{
							<Button
								onClick={submitForm}
								className="w-full h-10 font-bold my-2"
								disabled={!emailInput || !passwordInput || passwordInput.length < 6 || !isValidEmail(emailInput)}
							>
								<span>Sign up</span>
							</Button>
						}
						<div>
							<p
								className="text-sm text-gray-600 mb-0"
								onClick={() => {
									setVariant && setVariant('login')
								}}
							>
								Already have an account? <span className="text-link cursor-pointer">Login here</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default SignupContent
