import { MousePointerClick, Pencil } from 'lucide-react'
import { Button } from './ui/button'
import useEnterApp from '../hooks/enterApp'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../contexts/Auth'
import LoginModal from '../Components/Modals/Login'
import { useEffect, useState } from 'react'
import { useLocalStorage } from '../helpers/utility'

const GetStartedButton = ({
	redirectUrl,
	cta = 'Get Started',
	size = 'xl',
	justText = false,
	preventExitIntent = false,
}: {
	redirectUrl?: string
	cta?: string
	size?: 'xl' | 'default' | 'sm' | 'lg' | 'icon' | null | undefined
	justText?: boolean
	preventExitIntent?: boolean
}) => {
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const { currentUser } = useAuth() as any
	const history = useHistory()
	const { enterApp } = useEnterApp({ history })
	const [annualPlan] = useLocalStorage('annualPlan', true)
	const [modalShown, setModalShown] = useState(false)
	const [pageTimeElapsed, setPageTimeElapsed] = useState(false)

	useEffect(() => {
		// Set a timer to mark 5 seconds of page time
		const timer = setTimeout(() => setPageTimeElapsed(true), 5000)

		// Cleanup on unmount
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		const handleMouseOut = (event: MouseEvent) => {
			if (
				event.relatedTarget === null && // No related target (outside the browser window)
				event.clientY <= 0 && // Exited from the top of the page
				!modalShown && // Modal not shown yet
				preventExitIntent && // Exit intent prevention enabled
				pageTimeElapsed // User has been on the page for at least 5 seconds
			) {
				// Delay showing the modal by 500ms
				const delayTimer = setTimeout(() => {
					setModalShown(true)
					setLoginModalOpen(true)
				}, 500)

				// Cleanup the timer if the component unmounts
				return () => clearTimeout(delayTimer)
			}
		}

		// Add the event listener
		window.addEventListener('mouseout', handleMouseOut)

		// Cleanup on component unmount
		return () => {
			window.removeEventListener('mouseout', handleMouseOut)
		}
	}, [modalShown, preventExitIntent, pageTimeElapsed])
	const handleClick = () => {
		if (currentUser) {
			enterApp()
		} else {
			setLoginModalOpen(true)
		}
	}

	return (
		<>
			<LoginModal
				premiumPath={false}
				open={loginModalOpen}
				setModalOpen={(open: boolean) => {
					setLoginModalOpen(open)
				}}
				annualPlan={annualPlan}
				loginVariant="signup"
				redirectUrl={redirectUrl}
			/>
			<Button size={size} onClick={() => handleClick()}>
				{!justText && !currentUser && <MousePointerClick className="mr-2" />}
				{currentUser ? 'Dashboard' : cta}
				{!justText && !currentUser && (
					<span className="ml-1 text-base text-white/60 transition-all group-hover:text-white">- it's free</span>
				)}
			</Button>
		</>
	)
}

export default GetStartedButton
